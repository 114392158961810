import styled from 'styled-components';
import device from 'device';
import { Link } from 'react-router-dom';
import { ReactComponent as Visa } from 'assets/images/cards/visa.svg';
import { ReactComponent as Swap } from 'assets/images/icons/swap.svg';

export const Container = styled.div`
  margin: 0 auto;
  width: min-content;

  @media (max-width: 520px) {
    width: auto;
  }
`;

export const ExchangerWrapper = styled.div`
  z-index: 2;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  margin-bottom: 50px;
  @media ${device.mobile920} {
    margin-top: 35px;
    width: auto;
    margin-bottom: ${({ isAuthorized }) => (isAuthorized ? '150px' : '50px')};
  }
`;

export const ExchangerWrapperAdditional = styled.div`
  padding: 20px 40px;
  @media (max-width: 480px) {
    padding: 16px 20px 30px;
  }
  @media (max-width: 380px) {
    padding: 16px 10px 30px;
  }
`;

export const ButtonWrapper = styled.div`
  height: 49px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    min-width: 24px;
  }
`;

export const LabelText = styled.span`
  font-size: 1.1rem;
  padding-right: 5px;
  color: ${({ theme }) => theme.fullBlackText};
  @media ${device.mobile920} {
    font-size: 1.2rem;
  }
  @media (max-width: 340px) {
    font-size: 1.1rem;
  }
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.inputErrorColor};
  font-size: 0.8rem;
  position: absolute;
  bottom: -16px;
  left: 10px;
  height: 12px;
`;

export const RelativeWrapper = styled.div`
  position: relative;
  margin-bottom: 26px;
`;

export const HeaderCalculationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const VisaIcon = styled(Visa)`
  width: 66px;
  height: 22px;
  margin-left: auto;
  @media ${device.desktop} {
    width: 54px;
    height: 18px;
  }
  @media ${device.laptopL} {
    width: 48px;
    height: 16px;
  }
`;

export const SwapIcon = styled(Swap)`
  position: absolute;
  top: 56px;
  left: 10px;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  @media ${device.mobile920} {
    position: initial;
    margin: 0 auto;
    transform: rotate(90deg);
  }
`;

export const SwapIconWrapper = styled.div`
  height: 25px;
  width: 40px;
  position: relative;
  align-self: initial;
  @media ${device.mobile920} {
    align-self: center;
    width: auto;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  @media ${device.mobile920} {
    flex-direction: column;
  }
`;

export const SellContainer = styled.div``;

export const BuyContainer = styled.div``;

export const SystemInfoError = styled.div`
  font-size: 0.8rem;
  padding: 10px;
  text-align: center;
  border-top: ${({ theme }) => `1px solid ${theme.borderErrorColor}`};
  color: ${({ theme }) => theme.inputErrorColor};
  max-width: 100%;
  > div {
    max-width: fit-content;
    margin: 0 auto;
  }
`;

export const CardSelectWrapper = styled.div`
  position: relative;
`;

export const HomeWrapper = styled.div`
  margin: 25px auto;
  @media (max-width: 845px) {
    margin: 25px auto 0;
  }
`;

export const ExchangerBlock = styled.div`
  padding: 0 20px;
`;

export const CryptoNetwork = styled.div`
  color: ${props => props.theme.linkColor};
  line-height: 1;
  font-style: italic;
  font-weight: 500;
  font-size: 0.6rem;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const VipRate = styled(Link)`
  font-size: 0.9rem;
  position: absolute;
  top: -30px;
  height: 30px;
  color: ${props => props.theme.lightBlueWhite};
  display: flex;
  padding: 0 6px;
  justify-content: center;
  align-items: center;
  line-height: 19px;
  text-decoration: none;
  transition: color 0.2s;
  color: ${({ theme }) => theme.lightBlueWhite};
  &:hover {
    color: ${props => props.theme.fullWhite};
  }

  @media ${device.mobile920} {
    white-space: nowrap;
    top: -30px;
    left: 0;
    right: 0;
    padding: 0;
  }
`;

export const BitcashLink = styled.a`
  display: block;
`;

export const Banner = styled.img`
  width: 100%;
  max-width: 100%;
`;

export const CustomInputWrapper = styled.div`
  border: ${({ theme }) => `1px solid ${theme.grayBg}`};
  background-color: ${({ theme }) => theme.grayBg};
  border-radius: 8px;
  padding: 3px 10px;
  margin-bottom: 10px;
  position: relative;
  box-sizing: border-box;
  cursor: ${({ isWtModalInfoNeeded }) => isWtModalInfoNeeded && 'pointer'};
`;

export const Textarea = styled.input.attrs(props => ({
  type: props.type || 'text',
  placeholder: props.placeholder || undefined,
}))`
  font-family: 'Inter';
  display: block;
  font-size: 0.94rem;
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  padding: 0;
  color: ${({ isError, theme }) =>
    isError ? theme.inputErrorColor : theme.fullBlackText};
  border: none;
  width: ${({ inputHiddenText }) =>
    inputHiddenText ? 'calc(100% - 48px)' : '100%'};
  min-width: 45%;
  line-height: 38px;
  :disabled {
    -webkit-text-fill-color: ${props => props.theme.grayText};
  }
  ::placeholder {
    color: ${({ theme, placeholderColor }) =>
      placeholderColor || theme.fullBlackText};
    font-family: 'Inter';
    font-size: 0.94rem;
    left: 11px;
    @media ${device.laptop} {
      left: 9px;
    }
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-box-shadow: ${({ theme }) =>
      `0 0 0 30px ${theme.grayBg} inset !important;`};
    ::first-line {
      font-size: 0.94rem;
      font-family: 'Inter';
    }
  }
`;
