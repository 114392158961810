import axios from 'axios';
import { clearToken } from 'utils/services/request/localStorage';
import { HOME } from 'constants/routings';

export const registerFromOptions = {
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_URL}/register/client`,
};

export const loginFormOptions = {
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_TOKEN_URL}/oauth/token`,
  headers: {
    Authorization: 'Basic YXV0aENsaWVudElkOlhZN2ttem9OemwxMDA=',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

export const webmoneyLoginOptions = {
  method: 'get',
  url: `${process.env.REACT_APP_WEBMONEY_URL}/auth`,
};

export const confirmationEmailFormOptions = data => ({
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_URL}/user/code/verify`,
  data,
});

export const mfaQrOptions = {
  method: 'get',
  url: `${process.env.REACT_APP_AUTH_URL}/user/mfa/qr`,
};

export const twoFactorFromOptions = {
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_URL}/user/mfa/verify`,
};

export const mfaStatusOptions = {
  method: 'get',
  url: `${process.env.REACT_APP_AUTH_URL}/user/mfa/status`,
};

export const resendEmailOptions = {
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_URL}/user/code/send`,
};

export const logout = () => {
  axios.post(`${process.env.REACT_APP_AUTH_URL}/user/logout`).then(() => {
    clearToken();
    window.location.href = HOME;
  });

  return null;
};

export const supportFormOptions = {
  method: 'post',
  url: `${process.env.REACT_APP_KYC_URL}/support`,
};

export const confirmVerificationForm = () => {
  axios.post(`${process.env.REACT_APP_KYC_URL}/kyc/confirm`).then(response => {
    if (response.data.userConfirmed) {
      window.location.href = HOME;
    }
  });
};

export const restorePasswordInit = {
  method: 'get',
  url: `${process.env.REACT_APP_AUTH_URL}/user/password/recovery/send`,
};

export const geoInfo = {
  method: 'get',
  url: `${process.env.REACT_APP_AUTH_URL}/geo`,
};

export const restorePasswordActivated = {
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_URL}/user/password/recovery`,
};

export const changePassword = {
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_URL}/user/password/change`,
};

export const changeEmail = {
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_URL}/user/email/change/send`,
};

export const changeEmailVerify = {
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_URL}/user/email/change/verify`,
};

export const changePhone = {
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_URL}/user/phone/send`,
};

// this request made for residents Russian Federation and doesn't ask for a phone number verification code
export const changePhoneForRus = {
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_URL}/user/phone/update-for-rus`,
};

export const changePhoneVerify = {
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_URL}/user/phone/verify`,
};

export const changePhoneNotifications = data => ({
  method: 'put',
  url: `${process.env.REACT_APP_AUTH_URL}/user/phone`,
  data,
});

export const getOAuth = params =>
  `${process.env.REACT_APP_AUTH_URL}/authorize?${params}`;

export const setRegistrationPhone = {
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_URL}/user/phone/registration-send`,
};

export const setRegistrationPhoneVerify = `${process.env.REACT_APP_AUTH_URL}/user/phone/registration-verify`;

export const getEmailStatus = ({ email }) =>
  `${
    process.env.REACT_APP_AUTH_URL
  }/register/verify-by-email?email=${encodeURIComponent(email)}`;
