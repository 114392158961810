import styled from 'styled-components';
import device from 'device';
import { ReactComponent as Arrow } from 'assets/images/icons/operation-arrow.svg';
import { Button } from 'ui-kit/Button';

export const StyledTable = styled.table`
  z-index: 2;
  position: relative;
  background-color: ${props => props.theme.fullWhite};
  border-collapse: collapse;
  margin: -10px auto 50px;
  @media ${device.mobile920} {
    display: none;
  }
`;

export const StyledTableMobile = styled.table`
  border-collapse: collapse;
  margin: -10px auto 50px;
  display: none;
  width: 100%;
  @media ${device.mobile920} {
    display: block;
  }
  > thead {
    width: 100%;
    display: inline-block;
  }
  > thead * {
    width: 100%;
    display: inline-block;
  }
  > thead > tr > th {
    width: 50%;
  }
  > tbody {
    width: 100%;
    display: inline-block;
  }
  > tbody > tr {
    width: 100%;
    display: inline-block;
    display: flex;
  }
`;

export const TableHead = styled.th`
  padding: 0 15px 20px 15px;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: start;
  border-bottom: 1px solid #ededed;
  @media ${device.mobile920} {
    width: 50%;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 5px 15px 5px;
  }
`;

export const TableBody = styled.td`
  padding: 45px 15px 15px;
  font-size: ${({ isId }) => isId && '0.9rem'};

  &:first-child {
    position: relative;
  }

  &:last-child {
    display: flex;
    align-items: center;
  }

  @media ${device.mobile920} {
    width: 50%;
    padding: 35px 5px 15px;
  }
`;

export const OrderStatus = styled.div`
  font-size: 16px;
  text-align: center;
  padding: 5px 15px;
  border-radius: 24px;
  width: fit-content;
  white-space: nowrap;
  color: ${({ theme }) => theme.fullBlackText};
  @media ${device.mobile920} {
    margin: 5px 0;
    padding: 4px 10px;
    font-size: 14px;
  }
  background-color: ${({ status }) => {
    switch (status) {
      case 'CONFIRMED':
        return '#6AF7AE;';
      case 'REQUEST':
        return '#FFE6A7;';
      case 'PROCESSING':
        return '#FFE6A7;';
      case 'PAUSED':
        return '#FFE6A7;';
      case 'CHANGED':
        return '#FFE6A7;';
      case 'DECLINED':
        return '#FFD7DE;';
      case 'EXPIRED':
        return '#FFD7DE;';
      case 'REJECTED':
        return '#FFD7DE;';
      case 'ARREST':
        return '#FFD7DE;';
      default:
        return '#fff;';
    }
  }};
`;

export const DirectionOperation = styled.div`
  position: absolute;
  top: 10px;
  width: max-content;
  font-size: 0.9rem;
  color: ${props => props.theme.grayText};

  @media ${device.mobileL} {
    font-size: 0.8rem;
  }
`;

export const DataRow = styled.tr`
  cursor: pointer;
`;

export const AdditionalRow = styled.tr`
  @media ${device.mobile920} {
    cursor: pointer;
    display: inline-block;
    width: 100%;
    > td {
      width: 100%;
      display: inline-block;
    }
  }
`;

export const AdditionalRowTd = styled.td.attrs({
  colSpan: '100%',
})`
  padding: 0;
`;

export const OperationDate = styled.span`
  font-size: 0.9rem;
  color: ${props => props.theme.grayText};
  @media ${device.mobile920} {
    font-size: 0.8rem;
  }
`;

export const AdditionalInfoBlock = styled.div`
  -webkit-transition: max-height 0.3s linear;
  -moz-transition: max-height 0.3s linear;
  transition: max-height 0.3s linear;
  max-height: ${({ isActive }) => (isActive ? 'max-content' : '1px')};
  overflow: hidden;
  border-bottom: 1px solid #ededed;
  padding: 0 15px;
  @media ${device.mobile920} {
    padding: 0 5px;
    -webkit-transition: max-height 0.15s linear;
    -moz-transition: max-height 0.15s linear;
    transition: max-height 0.15s linear;
  }
`;

export const ArrowIcon = styled(Arrow)``;

export const ArrowIconActive = styled(Arrow)`
  path {
    stroke: ${({ $isActive, theme }) => !$isActive && theme.grayText};
  }
  transform: ${({ $isActive }) => ($isActive ? 'rotate(90deg)' : 'rotate(0)')};
  transition: transform 0.25s linear;

  @media ${device.mobile920} {
    margin: 0 auto;
  }

  @media ${device.mobileL} {
    overflow: visible;
    margin-left: 10px;
  }

  @media ${device.mobileM} {
    margin-left: 5px;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    margin-left: 10px;
  }

  @media ${device.mobile920} {
    margin-bottom: 5px;
  }
`;

export const CryptoNetwork = styled.span`
  color: ${props => props.theme.linkColor};
  line-height: 1;
  font-style: italic;
  font-weight: 500;
  font-size: 0.6rem;
`;

export const LabelText = styled.span`
  font-size: 1rem;
  color: ${props => props.theme.blackText};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media ${device.tablet} {
    font-size: 16px;
  }
  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

export const AdditionalData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.85rem;
  letter-spacing: 0.001em;
  color: ${props => props.theme.grayText};
  overflow-wrap: break-word;
  padding: 2px 0;
  &:first-child {
    margin-top: 10px;
    padding-top: 0;
  }
  &:last-child {
    margin-bottom: 10px;
    padding-bottom: 0;
    @media ${device.mobile920} {
      margin-bottom: 10px;
    }
  }
`;

export const EmptyLabel = styled.h4`
  width: max-content;
  margin: 0 auto;
  font-weight: 400;
  font-size: 1.5rem;
`;

export const ReportButton = styled(Button)`
  max-width: max-content;
  height: 45px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.btnColor};
  font-family: 'Inter';
  margin-bottom: 35px;
  padding: 10px;

  span {
    background: ${({ theme }) => theme.btnColor};
    height: 20px;
    width: 20px;
    border-radius: 20px;
    font-size: 9px;
    color: #fff;
  }

  svg {
    height: 17px;
    margin-right: 8px;
  }

  &:hover {
    background-color: inherit;
    color: ${({ theme }) => theme.btnColor};
  }
`;

export const ModalBodyWrapper = styled.div`
  max-width: 557px;
  margin: 0 auto;
`;

export const ModalLabel = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 125%;
  margin-bottom: 35px;
  color: ${({ theme }) => theme.blackText};
  @media ${device.mobile920} {
    margin-top: 60px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 35px;
  height: 58px;
  width: 100%;
  @media ${device.mobile920} {
    margin-top: 25px;
  }
`;

export const DatesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 14px 0;
  @media ${device.mobile920} {
    flex-direction: column;
    align-items: center;

    .date-picker {
      width: 100%;
    }
  }
`;

export const Splitter = styled.span`
  margin: 10px 5px auto 5px;
  @media ${device.mobile920} {
    margin: 0;
  }
`;
