import styled from 'styled-components';
import device from 'device';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DocumentsWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 24px;
  padding: 50px;
  > div {
    z-index: 2;
    position: relative;
  }
  @media ${device.laptopXL} {
    grid-template-columns: repeat(4, minmax(250px, 1fr));
  }
  @media ${device.laptopL} {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
  @media ${device.laptop} {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }
`;
