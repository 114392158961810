import {
  SETTINGS,
  OPERATION_HISTORY,
  PAYMENT_METHODS,
  NOTIFICATIONS,
} from 'utils/constants/profileTabs';

const transationEn = {
  translation: {
    welcome: 'Hello',
    register: 'Register',
    'customer-info': {
      signin: 'Sign in',
      registration: 'Registration',
      profile: 'Personal account',
      signup: 'Register',
      logout: 'Logout',
      verified: 'Verified',
      'email-confirmation': 'Confirm mail',
      'not-verified': 'Not verified',
      'status-email': 'Email status:',
      'not-confirmed': 'Not confirmed',
      twofa: 'Two-factor authentication',
      confirmed: 'Confirmed',
      'exist-from-account': 'Log out of account',
      changePassword: 'Change password',
      userProfile: 'My account',
      'pass-verification': 'Pass verification',
      text: {
        'didnt-receive-email': 'Didnt receive confirmation email?',
      },
      button: {
        'send-again': 'Send again',
      },
    },
    header: {
      menuItems: [
        { text: 'Questions and Answers' },
        { text: 'Media' },
        { text: 'Documents' },
        { text: 'Support' },
      ],
      whitebirdMessage: 'Whitebird message',
      myAccount: 'My Account',
      login: 'Login',
      registration: 'Registration',
      logout: 'Log out of account',
    },
    calculationView: {
      tonComment: 'Tag/Memo',
      mainTitle: 'Simple. Fast. Crypto.',
      mainTitleDesc: 'Buy and sell cryptocurrency',
      orderTypeSwitcher: {
        bankCard: 'Bank Card',
        bankAccount: 'Current account',
      },
      sellTitle: 'Sell',
      buyTitle: 'Receive',
      addressPlaceholder: 'Add crypto wallet address',
      walletPlaceholder: 'My wallet Whitebird',
      cardInput: {
        addCard: 'Add card',
        errorCardResponse: 'Error while retrieving card list',
        removeCardTitle: 'Remove card',
        removeCardMessage: 'Are you sure you want to remove the card?',
        removeCardAccept: 'Remove',
        infoApproveEmail: 'Confirm email for linking the card',
        infoApproveVerification: 'Pass verification',
        infoVerificationInProgress: 'Verification in progress',
        infoStatusUnavailable: 'Unavailable in this status',
        getCardTest: 'Pass test to get access to exchange',
      },
      wtInput: {
        addWt: 'Add current account',
        removeWtTitle: 'Remove account',
        removeWtMessage:
          'Are you sure you want to delete the checking account?',
        removeWtAccept: 'Remove',
        infoStatusUnavailable: 'Unavailable in this status',
      },
      increaseLimit: 'Expand',
      increaseLimitFull: 'Increase Limit',
      errors: {
        validationErrorOutDownOfLimit:
          'Minimum amount {{ value }} {{ currency }}',
        validationErrorOutUpOfLimit: 'Limit exceeded',
        validationErrorLimitExceeded: 'Limit exceeded',
        validationErrorWalletEmpty: 'Please enter an address',
        validationErrorInputAsserEmpty: 'The field must not be empty',
        validationErrorAlfaOnlyByn: 'This operation is available only in BYN',
        validationErrorCardEmpty: 'Add a bank card',
        validationErrorBankAccountEmpty: 'Add checking account',
        validationErrorWalletFormat: 'The address is not in the correct format',
        noCurrencyLimits: 'No limits have been set for this currency pair',
        invalidCorporateCard: 'Failure to work with system cards',
        invalidClientCard: 'You need to link a card',
        invalidCryptoAddress:
          'Invalid crypto address specified. If you have any questions, please contact support',
        responseErrors: {
          INVALID_SCAN_STATUS:
            'Initializing service, please try again in a few minutes',
          INVALID_ACTUAL_RATE:
            'The course for the selected pair is currently unavailable. Contact Support',
          INVALID_SPREAD:
            'Calculation of funds is not possible. Contact Support',
          INVALID_CHANGE_TO_ADDRESS:
            'Calculation of funds is not possible. Contact Support',
          INVALID_UTXO_SUM: 'Exchange direction temporarily unavailable',
          INVALID_INPUT_ADDRESS: 'Exchange direction temporarily unavailable',
          INVALID_FIAT_BALANCE: 'Exchange direction temporarily unavailable',
          FROZEN_CRYPTO_ADDRESS:
            'Your internal address for cryptocurrency replenishment is frozen. Contact Support',
          INVALID_EXTERNAL_RATE:
            'Failed to calculate the individual course. Contact Support',
          FORCE_BLOCKED_CLIENT:
            'A high risk of using the address for illegal activities has been identified. Contact Support',
          BAD_REQUEST: 'An unexpected error has occurred. Contact Support',
          INVALID_WT_STATUS: "Client doesn't have access to WT",
          INVALID_INTERNAL_CLIENT_BALANCE_AMOUNT:
            'Insufficient funds on the wallet',
        },
      },
      limitInfo:
        'minimum: {{ min }} {{ minCurrency }}, maximum: {{ max }} {{ minCurrency }} per operation',
    },
    partners: {
      headerFirstLine: 'Change legally.',
      headerSecondLine:
        'Whitebird is a Belarusian company with a legal crypto-license for an OTC exchange',
      cards: [
        { text: `Official\npartner` },
        { text: 'Official\npartner' },
        { text: 'Resident\nHigh Tech Park' },
        {
          textLabel: 'Ernst & Young Audit',
          text:
            'Verification of compliance with the «Regulations on the requirements that individual applicants must meet in order to register them as residents of the High-Tech Park».',
        },
      ],
    },
    mainPageFeatures: {
      features: [
        {
          mainText: 'Buy in a few clicks.',
          additionalText: 'Passport and selfie - all you need to register',
        },
        {
          mainText: 'Transfer money directly to your Visa card.',
          additionalText:
            'You no longer need to think about deposits, orders, withdrawals and other exchange difficulties',
        },
        {
          mainText: 'Get instant help from support.',
          additionalText: 'We are always in touch',
        },
      ],
    },
    faqPreview: {
      buttonFaqText: 'More questions',
      buttonWriteUsText: 'Write to us',
    },
    footerPreview: {
      title: 'We are in touch',
      mailUs: 'Message to Whitebird',
      addressInfo:
        'White Bird LLC,\nUNP: 591029489, jurid. address: 230023, Republic of Belarus, Grodno, st. Timiryazeva 37, office. 304.',
      copyrightSign: '© 2020–2021 Whitebird',
      aboutWhiteBird: `White Bird LLC is a resident of the Hi-Tech Park
        (the decision to register as a resident of the Park of High
        technologies, protocol No. 13/НС-2пр dated July 7, 2020) and implements
        its activities in accordance with the Decree of the President of the Republic
        Belarus of December 21, 2017 No. 8 and other legislation
        Republic of Belarus.`,
      ErnstAndYoung: `Verification of compliance with the requirements of the «Statement of Requirements,
        which individual applicants must meet to register
        them as residents of the Hi-Tech Park» White Bird LLC
        conducted by Ernst & Young LLC (EY Company) in January-April 2020
        years.`,
      securityText: `The official responsible for ensuring the protection of information in
         parts of personal data protection, specialist in system
         administration and information security - sec@whitebird.io`,
      footerLinks: [
        { label: 'Rules for handling personal data' },
        { label: 'Data processing policy' },
        { label: 'General conditions for the sale of tokens' },
        { label: 'Managing conflicts of interest' },
        { label: 'Risk Disclosure' },
        { label: 'Public offer' },
        { label: 'Dispute Resolution' },
      ],
      links: [
        { label: 'Questions and Answers' },
        { label: 'Media' },
        { label: 'Support' },
      ],
    },
    modals: {
      twoFa: {
        title: 'Disable 2FA',
        message:
          'Are you sure you want to disable 2-Step Verification? Disabling the feature may make your account more vulnerable. ',
        acceptText: 'Disable',
      },
      codeLabel: 'Verification code',
      cancel: 'Cancel',
      yes: 'Yes',
      errors: {
        notValid: 'The code you entered is invalid.',
        requiredField: 'Required field.',
      },
    },
    signInForm: {
      title: 'Login to your personal account',
      passPlaceholder: 'Your password',
      passLabel: 'Password',
      forgotPassword: 'Forgot your password?',
      signin: 'Sign in',
      signInMethods: {
        webmoney: 'WebMoney',
      },
      noAccountYet: 'Dont have an account yet?',
      register: 'Register',
      errors: {
        incorrectEmailFormat: 'The email address is not in the correct format.',
        requiredField: 'Required field.',
        passMin: 'Password must contain at least {{ count }} characters.',
        passMax: 'The password must be at most {{ count }} characters.',
        accountSigninLimited:
          'The number of login attempts has been exceeded. Account access is temporarily restricted',
        incorrectCreds: 'Login failed. Incorrect login or password.',
      },
    },
    signUpForm: {
      emailConfirmTitle: 'Email Confirmation',
      emailConfirmMessage1: 'By email',
      emailConfirmMessage2:
        'sent an email with a link. Follow the link in the confirmation email. ',
      linkSent: 'An email with a link has been sent.',
      newLinkAvailableIn: '{{time}} seconds until new mail can be sent.',
      codeLabel: 'Verification code',
      emailConfirmAccept: 'Confirm Email',
      emailConfirmed: 'Email Confirmed',
      emailNotConfirmed: 'Email not confirmed',
      twoFaDescription: 'For account security, please configure  n',
      twoFaDescription2: 'two-step verification.',
      twoFaDescription3:
        'Two-Step Verification is an additional layer of account protection. In addition to entering a password, you also need to enter a one-time code that comes to your email, phone or app. ',
      continue: 'Continue',
      later: 'Go through later',
      finish: 'Finish',
      emailResend: 'Email did not arrive, send it again!',
      codeSent: 'Verification code has been sent.',
      newCodeAvailableIn:
        '{{ time }} seconds until a new code can be received.',

      twoFaLabel: 'Two-factor authentication',
      twoFaRule1:
        '1. Download the Google Authenticator app from <1>Google Play</1> (Android) or <3>App Store</3> (iPhone).',
      twoFaRule2: '2. Open the app and select Set up account.',
      twoFaRule3: '3. Click Scan QR Code or use Code.',
      twoFaCodeInfo:
        '4. Enter the verification code from the app. You do not need to memorize these codes, as they change after each login.',
      twoFaIsOnLabel: 'Two-factor authentication is enabled',
      accountRegistered:
        'Your account is registered. To get full access to exchange operations, please go through verification.',
      skip: 'Skip',
      registerLabel: 'Register an account',
      partnerText: 'To continue the exchange for',
      passPlaceholder: 'Your password',
      passLabel: 'Password',
      agreement:
        'By registering in the Whitebird information system and creating an electronic personal account, you agree to the terms of the <1>Public offer</1> (including its integral parts: General conditions for the sale of tokens, Rules for handling personal data of clients, Regulation on user support)',
      createAccount: 'Create Account',
      alreadyHaveAccount: 'Already have an account?',
      accountLogin: 'Log in to your personal account',
      errors: {
        codeExpire: 'The code has expired. Please try sending the code again.',
        invalidCode: 'You entered an invalid code',
        requiredField: 'Required field.',
        userExists: 'A user with the specified address already exists',
        incorrectEmailFormat: 'The email address is not in the correct format.',
        passMin: 'Password must contain at least {{ count }} characters.',
        passMax: 'The password must be at most {{ count }} characters.',
        capsLockEnabled: 'Caps Lock is on',
      },
    },
    passwordForms: {
      titleChange: 'Change Password',
      titleRestore: 'Password Recovery',
      oldPassLabel: 'Old password',
      newPassLabel: 'New password',
      repeatedPassLabel: 'Confirm password',
      buttonChangeLabel: 'Change Password',
      buttonRestoreLabel: 'Restore password',
      codeLabel: 'Verification code',
      twoFaLabel: 'Two-factor authentication',
      twoFaCodeInfo:
        'Enter the verification code from the app. You do not need to memorize these codes, as they change after each login.',
      instructionLabel:
        'Please enter your email address to which we will send further instructions.',
      titleRestorePassword: 'Password reset message sent',
      titleConfirmEmail: 'Mail Confirmed',
      titleConfirmEmailError: 'Mail not confirmed',
      сonfirmEmailLabel:
        'Your mailbox has been successfully verified. To continue using the site, click Finish. ',
      сonfirmEmailLabelError:
        'Your mailbox has not been verified. Click Finish to continue using the site. ',
      restorePasswordLabel:
        'The message has been sent to the specified address. Follow the instructions in the message to regain access',
      restorePasswordLabelText:
        'To enter your personal account, use your e-mail and new password',
      newPasswordLabelText:
        'To recover your password, create a new password that will be used to log in.',
      doneButton: 'Done',
      titleSuccesfulChange: 'Password changed successfully',
      linkToHome: 'Go to home page',
      accountLogin: 'Log in to your personal account',
      submitCode: 'Submit Code',
      errors: {
        failedLink:
          'The link is invalid. Please create a new password recovery request.',
        codeExpire: 'The code has expired. Please try sending the code again.',
        invalidCode: 'You entered an invalid code',
        requiredField: 'Required field.',
        userExists: 'A user with the specified address already exists',
        incorrectEmailFormat: 'The email address is not in the correct format.',
        passMin: 'Password must contain at least {{ count }} characters.',
        passMax: 'The password must be at most {{ count }} characters.',
        wrongPassword: 'Passwords do not match',
        capsLockEnabled: 'Caps Lock is on',
        failedOldPassword: 'The old password is incorrect',
        somethingWentWrong:
          'Something went wrong, please try again or contact support',
      },
    },
    exchangeRate: 'Rate {{ rate }}',
    blockchainFee: 'Blockchain network commission {{ value }}',
    serviceFee: 'Service commission {{ value }}',
    converterCard: {
      fromCard: 'Send from card',
      toCard: 'Send to Card',
      notVerified: 'Only a verified user can link the card',
      notActiveCards: 'There are no active cards.',
      addCard: 'Add',
      removeCardTitle: 'Remove card',
      removeCardMessage: 'Are you sure you want to remove the card?',
      removeCardAccept: 'Remove',
      addNewCard: 'Add new card',
      errorAddingCard: 'Adding a new card is prohibited',
      error: 'Error!',
      invalidCorporateCard: 'Failure to work with system cards',
      invalidClientCard: 'You need to link a card',
      individualRate: 'Favorable rate for Belarusbank Visa cardholders',
      youSale: 'You sell:',
      youBuy: 'You are buying:',
      infoInstruction:
        'Sending from a personal address to the address of the exchanger. Follow further instructions.',
      receiveOnAddress: 'Receive to address',
      walletAddreess: 'Wallet address',
      exchange: 'Exchange',
      replenishement: 'Replenish',
      output: 'Withdraw',
      confirm: 'Confirm the application',
      errorInputAsserEmpty: 'The field must not be empty',
      linkToExchange: 'Go to Exchange',
      loginToExchange: 'Login to start exchange',
    },
    bankAccountViewer: {
      onCard: 'TO THE CARD',
      fromCard: 'FROM THE CARD',
      onBankAccount: 'TO CHECK ACCOUNT',
      fromBankAccount: 'FROM A CHECK ACCOUNT',
      sell: 'SELL',
      buy: 'BUY',
      title: 'Instructions for exchange',
      invoice: 'Invoice for payment',
      uploadBankAccountRequisites: 'Download',
      onAddress: 'TO ADDRESS',
      hash: 'HASH:\u0020',
      hidden: 'Hidden.',
      labelInstruction1:
        'Select the bank to which it is more convenient for you to transfer money. We will prepare an invoice for you in the next step.',
      labelInstruction2: 'Confirm Application',
      chooseBankAccount: 'Choose a bank',
      editBankAccount: 'Editing current account',
      bindBankaccount: 'Binding current account',
      titleAddNewBankAccount: 'Adding current account',
      descriptionAddNewBankAccount: `To add a new current account,
        
        send scans or photos of the banking service agreement with details to invoice@whitebird.io`,
      description1AddNewBankAccount:
        'As soon as our operator links the data to your account, it will appear in the list of available accounts',
      buttonOk: 'OK',
    },
    pending: {
      title: 'Instructions for exchange',
      instruction1:
        'Transfer {{ amount }} {{ currencyDescription }} from your wallet to the address:',
      instruction2:
        'After completing the translation, click the «I have transferred» button.',
      checkboxText: 'I am using a deferred payment service.',
      checkboxHover: `<i>Do not enable this option if you are not sure.\n\n Only for users who withdraw funds from services with deferred payment.</i>\n\n Some mining pools and exchanges do not withdraw their clients' cryptocurrency immediately, but in batches several times per day. We usually wait for a transaction to appear in the mempool within 30 minutes, after which it is rejected.\n\n To avoid premature rejection of the application, check the “Withdrawal from service with deferred payment” checkbox, and we will increase the waiting time for your funds. As soon as we receive the funds, you will be given a counter-offer in accordance with the rate at the time of receipt.`,
      errorLabel:
        'Important! Transferring cryptocurrency to another network will result in the loss of your funds. The amount indicated in the application must be sent to the address of the crypto exchanger in one transaction. The transfer fee is paid by you.',
      exchange: 'Exchange',
      confirm: 'Confirm the application',
      done: 'I have transferred',
      exchangeNumber: 'Exchange request No. {{ number }}',
      createdAt: 'Created {{operationDateLuxon}}',
      titleStatus: 'Application Status',
      updateStatus: 'Update status',
      cancelAfter: 'Order cancellation after:',
      cancelOrder: 'Cancel order',
      submitOrder: 'Accept conditions',
      termsUpdated: 'Attention! Terms of the deal have been updated',
      newTerms: 'New conditions',
      stoppedOperation:
        'Exchange operations for this currency are temporarily suspended. Try later',
      tempStopped:
        'Exchange operations for this currency have been temporarily stopped.',
      stoppedAllOperation:
        'Exchange operations are temporarily suspended. Try later.',
      recieveAnotherSum:
        'We have received from you an amount that differs from the one indicated in the application. The terms of the deal were recalculated and the terms of the deal were updated.',
      notRecieve:
        'We didnt receive your funds on time. The terms of the deal were recalculated and the terms of the deal were updated.',
      labelIfCancel:
        'If canceled, you will receive funds minus network or bank fees.',
      ratesWasUpdated:
        'The courses have been updated, please check the application conditions and retry your action',
      somethingWentWrong:
        'Something went wrong, please try again or contact support',
      newExchange: 'Make a new exchange',
    },
    belarusBankPage: {
      boldText1:
        'Holders of Belarusbank Visa payment cards can make an exchange in the Whitebird service at an even more favorable rate.',
      boldText2:
        'To take advantage of this benefit, link your Belarusbank Visa card to your account and select it as a means of payment.',
      boldText3: 'The best rate will be applied automatically.',
      text:
        'In 2018, Whitebird, together with Belarusbank, began to prepare the infrastructure for the seamless integration of Visa payment transactions into the cryptocurrency exchange process. The work was carried out with the participation of the payment provider Assist, the Banking Processing Center, as well as the Visa payment system.\n\nThe joint efforts resulted in the possibility of making quick settlements with Visa cards for Whitebird users, as well as reducing transaction costs for Belarusbank customers.',
    },
    support: {
      nameSurname: 'First Name Last Name',
      introduce: 'Introduce yourself',
      topic: 'Subject matter',
      messageProblem: 'Describe the problem',
      youMessage: 'Your message',
      validFormats: 'Valid file formats: JPEG, JPG, PNG, PDF.',
      maxFiles: 'Number of files: no more than 5.',
      maxSizes: 'Maximum file size: 10 MB.',
      send: 'Send',
      titleSupport: 'Support Service',
      requestSupport: 'A support request has been sent',
      checkboxText:
        'By clicking on the "Submit" button, I consent to the processing of my personal data in order to receive feedback in accordance with the',
      personalDataProcessingPolicy: 'Personal Data Processing Policy',
      errors: {
        requiredField: 'Required field.',
        userExists: 'A user with the specified address already exists',
        incorrectEmailFormat: 'The email address is not in the correct format.',
        passMin: 'Password must contain at least {{ count }} characters.',
        passMax: 'The password must be at most {{ count }} characters.',
        nameMax: 'The name must be at most {{ count }} characters.',
        topicMax: 'The topic must be at most {{ count }} characters long.',
        messageMax: 'The message must not exceed {{ count }} characters.',
        filesMax: 'It is possible to upload no more than {{ count }} files',
        emailTrim: 'Email address must not have leading or trailing spaces',
        capsLockEnabled: 'Caps Lock is on',
      },
    },
    noMatch: {
      titleCode: '404',
      message: 'Page not found',
      infoMessage:
        'The page you wanted to go to was not found. Perhaps an incorrect address was entered or the page was deleted.',
      redirectToHomePage: 'Go to home page',
    },
    verification: {
      countryFlag: 'I am a citizen of the Republic of Belarus',
      residencePermitInBelarusPresent: 'I am a citizen of another country',
      exchangeInPersonalInterests:
        'I perform exchange operations in my own interests and do not represent the interests of third parties',
      notUSTaxpayer: 'I am not a US taxpayer',
      addQuiz:
        'Citizens of the Republic of Belarus need to pass an additional survey',
      residence:
        'Availability of a residence permit in the Republic of Belarus for foreign citizens',
      chooseCitizenship: 'Choose Citizenship ...',
      chooseDocumentType: 'Choose Document Type ...',
      document: 'Identity document',
      documentType: 'Document Type',
      nationality: 'Citizenship',
      identityDocId: 'Series and number',
      identityDocDate: 'Issue date',
      identityDocIssuer: 'Issued by',
      placeOfBirth: 'Place of birth',
      country: 'Country',
      region: 'Region',
      district: 'District',
      city: 'City/Town',
      street: 'Street',
      house: 'House',
      flat: 'Flat',
      postCode: 'Postcode',
      residenceAsRegistered:
        'I confirm that the place of residence and registration is the same',
      supportingDocuments: 'Supporting Documents',
      skanOrPhoto:
        'To confirm the entered data, attach a scanned copy or photo:',
      label1: '1) A spread of a document with a photo and full name;',
      label2:
        '2) Fold-over of a document with information about the place of birth (if the information is found on a separate page);',
      label3: '3) A spread of the document with the registration address.',
      validFormats: 'You can upload JPEG, JPG, PNG or PDF files.',
      maxSizes: 'Maximum file size: 10 MB.',
      titleGeneralInformation: 'General Information',
      name: 'Name',
      patronymic: 'Patronymic',
      surname: 'Surname',
      birthday: 'Date of birth',
      titleHome: 'Residence',
      chooseCountry: 'Select a country ...',
      residenceAndRegistration:
        'I confirm that the place of residence and registration is the same',
      residenceDocument: 'Document proving the place of residence',
      residenceConfirm:
        ' To confirm your actual place of residence, please provide one of the following documents: a notice (invoice) on the amount of payment for housing and communal services, payment for the use of residential premises, confirmation of payment of utility bills (gas, electricity, water, etc.), lease agreement for living quarters.',
      otherInformation: 'Other information',
      uploadDocument: 'Upload Document',
      photoWithDocument: 'Photo with document',
      step3label1: '- Your gaze should be directed directly to the camera;',
      step3label2:
        '- Please make sure that every detail of the document is clearly visible;',
      step3label3:
        '- Fingers should not cover any important information on the document;',
      step3label4: '- Do not wear hats, glasses or cover your face.',
      step3label5:
        '1. The document should not cover your face, but the gaze should be directed directly into the camera',
      step3label6:
        '2. Fingers should not cover the information on the document',
      step3label7: '3. Let your loved one or friend take a picture of you',
      quizLoadError: 'Quiz load error',
      sumSubWizardTitle: 'User verification',
      sumSubWizardDescription:
        'We are legally obligated to verify and check our clients. Before you can make financial transactions on Whitebird, please verify your account.',
      sumSubWizardNext: 'Next',
      doneButton: 'Done',
      errorMessage: 'An error occurred - reload the page and try again',
    },
    pageTitle: {
      main: 'Home',
      faq: 'Questions - answers',
      support: 'Support',
      security: 'Security',
      about: 'About the service',
      belarusbank: 'Get a favorable rate with Belarusbank Visa cards',
      dispute: 'Dispute Resolution',
      media: 'Media',
      userProfile: 'Profile',
      documents: 'Documents',
    },
    disputeInfoText:
      'If you believe that White Bird LLC has violated your rights by its actions or inaction, you can apply for protection of your violated rights to the judicial or other authorized bodies.',
    processingStatuses: {
      name: {
        request: 'Request',
        inProgress: 'In Progress',
        confirm: 'Completed',
        reject: 'Rejected',
        arest: 'Arrest',
        changed: 'Terms of the deal have been updated',
      },
      description: {
        request: 'Order accepted',
        confirmation:
          'Order confirmed. Waiting for the payment confirmation from the bank',
        confirmationBuy: 'Order confirmed. Waiting for cryptocurrency accept',
        preparation: 'Bank accepted payment order. Waiting for funds',
        preparationBuy: 'Order processed to the bank. Wait for funds',
        processing:
          'Payment successfulю Сryptocurrency transfer is in progress',
        processingBuy: 'Payment successful. Wait for funds',
        pending: 'Cryptocurrency transaction in the queue to the block',
        selection: 'Cryptocurrency transaction pending confirmation',
        crypConfirmation:
          'Cryptocurrency transaction confirmed. Funds credited to your wallet',
        cryptConfirmationBuy:
          'Cryptocurrency transaction confirmed. Wait for fiat funds to arrive',
        rejection: 'Client canceled the order',
        expiration: 'Timeout expired',
        declined: 'Order rejected',
        arrest:
          'We have blocked your order. The financial control of the crypto wallet address revealed a high degree of risk ',
        timeout: 'The timeout for receiving funds has expired',
        invalidAmount: 'Invalid amount received from client',
      },
    },
    statuses: {
      notVerified: 'Not verified',
      pending: 'Verifying',
      verified: 'Verified',
      frozen: 'Frozen',
      merchant: 'Merchant',
    },
    workTimeErrors: {
      amlCurrencyDisabled:
        'Transactions with residents of Belarus are temporarily made only in Belarusian rubles',
      operationDayDisabled: 'Exchange operations stopped',
      operationDisabled: `For the selected pair, exchange operations are temporarily disabled`,
    },
    loading: {
      error: 'Error while getting data',
    },
    media: {
      title: 'Media',
      source: 'Source',
      datePublish: 'Date publish:',
      additionalMediaTitle: 'Another posts',
      additionalPosts: 'Another posts abous us',
      moveToExchange: 'Go to exchange',
      types: {
        all: 'All',
        media: 'Media',
        event: 'Events',
        promotion: 'Promotions',
      },
    },
    merchantPage: {
      title: 'Merchant account',
      options: 'Options',
      key: 'API key',
      webhookUrl: 'Webhook URL',
      uploadURL: 'Client data export URL',
      showName: 'Name to display to the client',
      save: 'Save',
      externalValidationUrl: 'External validation URL',
      availablePairs: 'Available pairs',
      buy: 'Buy',
      sell: 'Sell',
      balances: 'Balances',
      operationLists: 'Operations list',
      number: 'Number',
      creationDate: 'Creation date',
      clientId: 'Client ID',
      pair: 'Pair',
      inputAsset: 'Input asset',
      outputAsset: 'Output asset',
      direction: 'Direction',
      transactionStatus: 'Transaction status',
      fiatTransactionStatus: 'fiat',
      cryptoTransactionStatus: 'crypto',
      operationStatus: 'Operation status',
      from: 'from',
      prevPage: 'Prev page',
      nextPage: 'Next page',
      balancesList: 'Balance history',
      asset: 'Asset',
      currency: 'Currency',
      changeType: 'Change type',
      merchantId: 'Merchant ID',
      total: 'Total',
      balance: 'balance',
      id: 'ID',
      webhookTableCaption: 'Webhooks management',
      webhookStatus: 'Status',
      webhookType: 'Type',
      webhookMessage: 'Message text',
      webhookCreationDate: 'Creation date',
      webhookLastSentDate: 'Last sent',
      webhookAction: 'Action',
      webhooks: 'Webhooks',
      payments: 'Payments',
      paymentsHeader: 'Payments history',
      newPaymentHeader: 'New payment',
      paymentType: 'Type',
      paymentDate: 'Date',
      paymentAddress: 'Address',
      paymentAmount: 'Amount',
      generatePayment: 'Make payment',
      paymentStatuses: {
        NEW: 'New',
        NOT_FOUND: 'Not found',
        REJECTED: 'Rejected',
        TIMEOUT: 'Timeout',
        EXPIRED: 'Expired',
        INVALID_AMOUNT: 'Invalid amount',
        ERROR: 'Error',
        AML_ERROR: 'AML error',
        ARREST: 'Arrest',
        PENDING: 'Pending',
        SELECTED: 'Selected',
        CONFIRMED: 'Confirmed',
        REQUEST: 'Active',
      },
      paymentCancel: 'Cancel',
      iSent: 'I have already transferred',
      paymentWarning:
        'Important! Transfer the entire amount in one transaction. The transfer fee is paid by you.',
      createPaymentError: 'Error: merchant has an active payment',
      paymentRequisites: 'Payment requisites',
      optionsPairs: 'Options & pairs',
      operations: 'Exchange operations',
      pairLabel: 'Currency pair',
      resend: 'Send',
      credit: 'Permissible negative balance (total credit)',
      debt: 'Current negative balance',
      addFilter: 'Filter',
      applyFilter: 'Apply',
      reportModalHeader: 'Report',
      creationDateLabel: 'Creation date',
      completionDateLabel: 'Completion date',
      orderTypesLabel: 'Order types',
      orderStatusesLabel: 'Order statuses',
      providerTypesLabel: 'Provider types',
      fiatStatusesLabel: 'Fiat statuses',
      cryptoStatusesLabel: 'Crypto statuses',
      fiatCurrenciesLabel: 'Fiat currencies',
      cryptoCurrenciesLabel: 'Crypto currencies',
      report: 'Report',
      downloadReport: 'Download report',
      search: 'Search',
      resetFilter: 'Reset filter',
      validation: {
        utmKeyMin: 'Minimum {{ count }} characters',
        utmKeyMax: 'Maximum {{ count }} characters',
        dateFrom: "Start date can't be more than today",
        dateTo: "End date can't be before start date",
        invalidUrl: 'Invalid URL',
        invalidName: 'Invalid name',
      },
      filterItems: {
        types: {
          DEFAULT: 'Default',
          DEPOSIT: 'Deposit',
          WITHDRAWAL: 'Withdrawal',
        },
        providerTypes: {
          ASSIST: 'Assist',
          CA: 'CA',
          BGP: 'BGP',
          BEPAID: 'BEPAID',
        },
        statuses: {
          REQUEST: 'Request',
          PROCESSING: 'Processing',
          PAUSED: 'Paused',
          CHANGED: 'Changed',
          CONFIRMED: 'Confirmed',
          DECLINED: 'Declined',
          EXPIRED: 'Expired',
          REJECTED: 'Rejected',
          ARREST: 'Arrest',
        },
        fiatStatuses: {
          NEW: 'New',
          REJECTED: 'Rejected',
          TIMEOUT: 'Timeout',
          DECLINED: 'Declined',
          ERROR: 'Error',
          PENDING: 'Pending',
          PROCESSING: 'Processing',
          APPROVED: 'Approved',
        },
        cryptoStatuses: {
          NEW: 'New',
          NOT_FOUND: 'Not found',
          REJECTED: 'Rejected',
          TIMEOUT: 'Timeout',
          INVALID_AMOUNT: 'Invalid amount',
          ERROR: 'Error',
          AML_ERROR: 'AML error',
          ARREST: 'Arrest',
          PENDING: 'Pending',
          SELECTED: 'Selected',
          CONFIRMED: 'Confirmed',
        },
        fiatCurrencies: {
          USD: 'USD',
          RUB: 'RUB',
          BYN: 'BYN',
          EUR: 'EUR',
        },
        cryptoCurrencies: {
          BTC: 'BTC',
          ETH: 'ETH',
          USDT: 'USDT',
          TRX: 'TRX',
          USDT_TRC: 'USDT',
          TON: 'TON',
          USDT_TON: 'USDT',
          WBP: 'WBP',
        },
        changeTypes: {
          EXCHANGE_OPERATION: 'Exchange operation',
          MANUAL: 'Updated by system',
          EXCHANGE_REWARD: 'Exchange reward',
          DEPOSIT: 'Deposit',
          WITHDRAWAL: 'Withdrawal',
        },
      },
    },
    filters: {
      currencies: 'Currency',
      changeTypes: 'Change type',
      creationDateFrom: 'Creation from',
      creationDateTo: 'Creation to',
      completionDateFrom: 'Completion from',
      completionDateTo: 'Completion to',
      types: 'Order types',
      providerTypes: 'Providers',
      statuses: 'Statuses',
      fiatStatuses: 'Fiat statuses',
      cryptoStatuses: 'Crypto statuses',
      fiatCurrencies: 'Fiat currencies',
      cryptoCurrencies: 'Crypto currencies',
      utmKey: 'UTM key',
      clientId: 'Client ID',
    },
    assistErrors: {
      NOT_SUFFICIENT_FUNDS:
        'The transaction was rejected by the bank. Insufficient funds on the card.',
      DECLINED: 'The transaction was rejected by the bank. Contact your bank.',
      AUTH_3D_SECURE_DECLINED:
        'The transaction was rejected by the bank. The 3D code was not entered or was entered incorrectly.',
      LIMIT_EXCEEDED:
        'The transaction was rejected by the bank. The transaction limit has been exceeded.',
      CARD_EXPIRED:
        'The transaction was rejected by the bank. The card has expired.',
    },
    promocode: {
      inputPlaceholder: 'I have a promo code',
      applyButtonText: 'Apply',
      resetButtonText: 'Cancel',
      appliedPromo: 'Promocode {{ code }}',
      orderPromoInfo: 'Bonus {{ value }} using promocode {{ code }}',
      errors: {
        INVALID_PROMO_CODE: {
          title: 'Invalid promo',
          description:
            'Invalid promo Invalid promo Invalid promo Invalid promo Invalid promo Invalid promo',
        },
        INVALID_PROMO_CODE_CURRENCY: {
          title: 'Invalid promo currency',
          description: 'Invalid promo currency 😔',
        },
        INVALID_PROMO_CODE_DISCOUNT: {
          title: 'Invalid promo discount',
          description: 'Invalid promo discount 😔',
        },
      },
      emptyField: 'You have not entered a promo code',
      promoMin: 'Promocode must contain at least {{ count }} characters.',
      promoMax: 'The promocode must be at most {{ count }} characters.',
      promoFormat: 'Promo code entered incorrectly.',
    },
    chargeRow: {
      included: 'Included:',
      serviceFee: 'Комисcия сервиса {{ value }}',
      serviceFeeDescription:
        'The commission includes <strong>all</strong> expenses for card processing, bank services and Whitebird service.',
      blockchainFee: 'Комисcия сети блокчейн {{ value }}',
      blockchainFeeDescription: '',
      bonus: 'Bonus {{ value }}',
    },
    exchangeDescription: {
      title: 'Обменивайте выгодно',
      buy: 'Покупка',
      sell: 'Продажа',
      onSum: 'На сумме {{ value }}',
      cardTitles: {
        commonExchange: 'Обычная \n Криптобиржа',
        whitebirdExchange: 'Криптообменник \n Whitebird',
      },
      buyCommonDescInfo:
        'Ввод фиата 3% - 3.5% \n Обмен 0.2% - 0.4% \n Вывод крипты 0.0001 - 0.0005 BTC \n Итого 3.2% - 4% + 0.0003 - 0.0005 BTC',
      sellCommonDescInfo:
        'Пополнение 0% \n Обмен 0.2% - 0.4% \n Вывод фиата 3% - 3.5% \n Итого 3.2% - 4%',
      buyWhitebirdDescInfo: 'Итого ~4.5% \n',
      sellWhitebirdDescInfo: 'Итого ~3.5% + комиссия блокчейна \n',
      whitebirdTip:
        'В комиссию включены все расходы по процессингу карты, услуги банка и сервиса Whitebird',
    },
    notifications: {
      clearAll: 'Clear all',
      noNewNotifications: 'No new notifications',
      noNotificationData: 'No notifications',
      allNotificationLink: 'All notifications',
    },
    profile: {
      pageTabs: {
        exchange: 'Обменять',
        [SETTINGS]: 'Настройки',
        [OPERATION_HISTORY]: 'История операций',
        [PAYMENT_METHODS]: 'Способы оплаты',
        [NOTIFICATIONS]: 'Уведомления',
      },
      settings: {
        privateData: 'Личные данные',
        accountLogin: 'Вход в аккаунт',
        notifications: 'Уведомления',
        changePassword: 'Изменить пароль',
        changePasswordSave: 'Сохранить',
        changePasswordCancel: 'Отмена',
      },
      inputLabels: {
        email: 'E-mail',
        phone: 'Телефон',
        emailNotification: 'Получать уведомления на Email',
        phoneNotification: 'Получать уведомления на телефон',
        twoFa: 'Двухэтапная аутентификация',
        oldPassword: 'Старый пароль',
        newPassword: 'Новый пароль',
        code: 'Проверочный код',
      },
      modalLabels: {
        approveButton: 'Подтвердить',
        decline2Fa: 'Отключение двухфакторной аутентификации',
        decline2FaDesc:
          'Вы действительно хотите отключить двухэтапную аутентификацию? Отключение функции может сделать ваш аккаунт более уязвимым.',
        confirmChangePassword: 'Подтвердите смену пароля',
        confirmChangePasswordDesc:
          'Введите подтверждающий код из приложения. Не нужно запоминать эти коды, так как они меняются после каждого входа.',
      },
    },
    tooltip: {
      copied: 'Copied',
      copy: 'Copy',
    },
    cryptoVibeModal: {
      title: 'Crypto card fee reduction',
      sell: 'Sell crypto',
      buy: 'Buy crypto',
      button: 'Learn more',
      checkboxTitle: 'No more reminders for today',
    },
  },
};

export default transationEn;
