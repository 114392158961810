import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { isEmpty } from 'ramda';
import Modal, { Dialog, DialogModal, SimpleModal } from 'ui-kit/Modal/Modal';
import DotPreloader from 'ui-kit/DotPreloader';
import ClientContext from 'utils/contexts/Client';
import ModalContext from 'utils/contexts/Modal';
import Iframe from 'ui-kit/Iframe/Iframe';
import { convertCardNumber } from 'utils/number';
import {
  useAxiosStateWithRefetch,
  useAxiosStateWithRefetchNoFirstCall,
} from 'utils/hooks/axiosHook';
import {
  clientInit,
  clientInfo,
  clientValidate as clientValidateRequest,
  getCryptoCardStatus as getCardStatusRequest,
} from 'utils/services/request/alfa';
import { changeCardName } from 'utils/services/request/card';
import {
  CRYPTO_CARD_STATUS,
  FRAME_EVENT_STATUS,
  VALIDATION_CLIENT,
} from 'utils/constants/alfa';
import DefaultTooltip from 'ui-kit/Tooltip/DefaultTooltip';
import { pushAnalyticsEvent } from 'components/App/analytics';
import RenameCardModal from 'components/Auth/UserProfile/PaymentMethodList/RenameCardModal';
import CardsCommissionInfo from 'components/Auth/UserProfile/PaymentMethodList/CardsCommissionInfo';
import FaceLiveness from 'components/Regula/FaceLiveness';
import LivenessCheck from 'components/Verification/SumSub/LivenessCheck';
import ConsentForm from './ConsentForm';
import ConfirmationForm from './ConfirmationForm';
import {
  CryptoCardWrapper,
  AttachCardButton,
  Button,
  ButtonsWrapper,
  CardName,
  CardNumber,
  CardWrapper,
  LoadWrapper,
  WrapperAttachButtons,
  ErrorIcon,
  MastercardLogo,
  CardRow,
  CardLoadError,
  CardsInfoWrapper,
} from '../styled-ui';

const CryptoCardManagement = ({
  t,
  cardsInformation,
  attachCardRequest,
  getRemoveCardUrl,
  getCardDetailsRequest,
  commissionInformation,
}) => {
  const client = useContext(ClientContext);
  const { openModal } = useContext(ModalContext);
  const [cryptoCardStatus, setCryptoCardStatus] = useState(null);
  const [isShowUserDetailsModal, setIsShowUserDetailsModal] = useState(false);
  const [isShowConsentForm, setIsShowConsentForm] = useState(false);
  const [isLivenessCheck, setIsLivenessCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cardIdToRemove, setCardIdToRemove] = useState(null);
  const [cardName, setCardName] = useState('');
  const [requestCardId, setRequestCardId] = useState(null);
  const isLivenessRegula = client?.data?.internalBalanceEnabled;
  const [cardToRename, setCardToRename] = useState({
    open: false,
    id: '',
    providerType: '',
  });
  const [cryptoCardFrame, setCryptoCardFrame] = useState({
    open: false,
    url: '',
  });

  const getCryptoCardStatus = useAxiosStateWithRefetch(getCardStatusRequest);
  const clientValidate = useAxiosStateWithRefetchNoFirstCall(
    clientValidateRequest
  );
  const attachCryptoCard = useAxiosStateWithRefetchNoFirstCall(
    attachCardRequest
  );
  const cryptoCardDetails = useAxiosStateWithRefetchNoFirstCall(
    getCardDetailsRequest({ id: requestCardId })
  );

  const isUserNotResident = clientValidate?.data?.validationErrors.some(error =>
    [
      VALIDATION_CLIENT.RESIDENCE_SHOULD_BE_SETUP,
      VALIDATION_CLIENT.RESIDENCE_ERROR,
    ].includes(error)
  );
  const isUserNotHavePhone = clientValidate?.data?.validationErrors.some(
    error =>
      [
        VALIDATION_CLIENT.PHONE_NUMBER_SHOULD_BE_SETUP,
        VALIDATION_CLIENT.PHONE_WRONG_FORMAT,
      ].includes(error)
  );
  const isUserNotHaveGender = clientValidate?.data?.validationErrors.includes(
    VALIDATION_CLIENT.GENDER_SHOULD_BE_SETUP
  );
  const isThereUserData = !isUserNotHavePhone && !isUserNotHaveGender;
  const isCryptoCardNotCreated =
    cardsInformation.loaded &&
    getCryptoCardStatus.loaded &&
    !cryptoCardStatus?.cardRbsNumber &&
    !cryptoCardStatus?.status;
  const isRequiredAttachManually =
    cardsInformation.loaded &&
    isEmpty(cardsInformation.data) &&
    getCryptoCardStatus.loaded &&
    !cryptoCardStatus?.hasInactiveCryptoCard &&
    (cryptoCardStatus?.status === CRYPTO_CARD_STATUS.CARD_CREATED ||
      cryptoCardStatus?.status === CRYPTO_CARD_STATUS.CARD_PIN_CREATED ||
      cryptoCardStatus?.status === CRYPTO_CARD_STATUS.CARD_ACTIVATED ||
      cryptoCardStatus?.status === CRYPTO_CARD_STATUS.CARD_RBS_BIND_ERROR);
  const isCryptoCardReleaseError =
    isRequiredAttachManually && !cryptoCardStatus?.cardMask;

  useEffect(() => {
    if (isShowUserDetailsModal || isShowConsentForm) {
      document.documentElement.classList.add('is-locked');
    }
    return () => {
      document.documentElement.classList.remove('is-locked');
    };
  }, [isShowUserDetailsModal, isShowConsentForm]);

  useEffect(() => {
    if (getCryptoCardStatus.data) {
      setCryptoCardStatus(getCryptoCardStatus.data);
    }
  }, [getCryptoCardStatus.data]);

  const handleCreateCard = () => {
    pushAnalyticsEvent('release_crypto_card_click');
    clientValidate.fetch();
  };

  useEffect(() => {
    if (clientValidate.loaded) {
      if (clientValidate.error) {
        openModal({
          isError: true,
          message: t('errorModal.title'),
        });
      } else if (isUserNotResident) {
        openModal({
          isError: true,
          message: t('profile.cryptoCardActions.error.residence'),
        });
      } else if (isThereUserData) {
        setIsShowConsentForm(true);
      } else {
        setIsShowUserDetailsModal(true);
      }
    }
  }, [
    clientValidate.loaded,
    clientValidate.error,
    isUserNotResident,
    isThereUserData,
  ]);

  const handleCheckInMsi = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(clientInit);
      if (response?.data?.msi) {
        await setCryptoCardFrame({
          open: true,
          url: response.data.redirectUrl,
        });
      } else {
        await setIsLivenessCheck(true);
      }
      await setIsShowConsentForm(false);
      await setIsLoading(false);
    } catch {
      setIsShowConsentForm(false);
      setIsLoading(false);
      openModal({
        isError: true,
        message: t('errorModal.title'),
      });
    }
  };

  const handleGetClientInfo = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(clientInfo);
      if (response?.data?.redirectUrl) {
        await setCryptoCardFrame({
          open: true,
          url: response.data.redirectUrl,
        });
        await setIsLivenessCheck(false);
        await setIsLoading(false);
      }
    } catch {
      setIsLivenessCheck(false);
      setIsLoading(false);
      openModal({
        isError: true,
        message: t('errorModal.title'),
      });
    }
  };

  const handleAttachCard = () => {
    attachCryptoCard.fetch();
  };

  const handleCardDetails = id => {
    setRequestCardId(id);
    cryptoCardDetails.fetch();
  };

  useEffect(() => {
    if (attachCryptoCard?.data?.url) {
      window.location.replace(attachCryptoCard?.data?.url);
    } else if (cryptoCardDetails?.data?.url) {
      setCryptoCardFrame({ open: true, url: cryptoCardDetails?.data?.url });
    } else if (attachCryptoCard.error || cryptoCardDetails.error) {
      openModal({
        isError: true,
        message: t('errorModal.title'),
      });
    }
  }, [
    attachCryptoCard.data,
    cryptoCardDetails.data,
    attachCryptoCard.error,
    cryptoCardDetails.error,
  ]);

  useEffect(() => {
    const handler = event => {
      const status = event.data?.status?.toUpperCase();
      if (
        status === FRAME_EVENT_STATUS.OK ||
        status === FRAME_EVENT_STATUS.SUCCESS ||
        status === FRAME_EVENT_STATUS.ERROR
      ) {
        setCryptoCardFrame({ open: false, url: '' });
        getCryptoCardStatus.refetch();
        cardsInformation.refetch();
      }
    };
    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, []);

  const handleRenameCard = (id, providerType, name) => {
    setCardToRename({ open: true, id, providerType, name });
    setCardName(name);
  };

  const handleSetCardName = async () => {
    setCardToRename(prev => ({ ...prev, open: false }));
    await axios.put(
      changeCardName(cardToRename?.id, cardToRename?.providerType),
      { name: cardName }
    );
    await cardsInformation.refetch();
  };

  const deleteUserCard = async id => {
    setCardIdToRemove(null);

    await axios.post(getRemoveCardUrl(id));
    await cardsInformation.refetch();
    await getCryptoCardStatus.refetch();
  };

  return (
    <>
      <CardsInfoWrapper>
        <CardsCommissionInfo
          t={t}
          commissionInformation={commissionInformation}
          text="profile.cardActions.commissionInfoAlfa"
        />
        <AttachCardButton
          type="button"
          disabled={isLoading}
          onClick={handleAttachCard}
        >
          {t('profile.cardActions.attachCardAction')}
        </AttachCardButton>
      </CardsInfoWrapper>
      {getCryptoCardStatus.error && isEmpty(cardsInformation.data) ? (
        <CardLoadError margin="0">
          {t(`calculationView.cardInput.errorCardResponse`)}
        </CardLoadError>
      ) : (
        <CryptoCardWrapper>
          {(cardsInformation.fetching || getCryptoCardStatus.fetching) && (
            <LoadWrapper>
              <DotPreloader dotColor="#0069ff" />
            </LoadWrapper>
          )}
          {isRequiredAttachManually ? (
            <>
              {cryptoCardStatus?.cardMask ? (
                <CardRow>
                  <CardWrapper>
                    <CardNumber>
                      <DefaultTooltip
                        margin="0 5px 0 0"
                        tooltipText={t(
                          'profile.cryptoCardActions.error.failure'
                        )}
                      >
                        <ErrorIcon />
                      </DefaultTooltip>
                      <MastercardLogo />
                      {convertCardNumber(cryptoCardStatus?.cardMask)}
                    </CardNumber>
                  </CardWrapper>
                  <ButtonsWrapper>
                    <Button
                      color="orange"
                      type="button"
                      onClick={handleAttachCard}
                    >
                      {t('profile.cardActions.attachCardManually')}
                    </Button>
                    <Button type="button" onClick={handleCardDetails}>
                      {t('profile.cardActions.detailsCard')}
                    </Button>
                  </ButtonsWrapper>
                </CardRow>
              ) : (
                <CardLoadError margin="20px 0 0 0">
                  {t(`profile.cryptoCardActions.error.unexpected`)}
                </CardLoadError>
              )}
            </>
          ) : (
            <>
              {cardsInformation?.data?.map(
                ({ number, id, name, providerType }) => (
                  <CardRow key={id}>
                    <CardWrapper>
                      <CardNumber>
                        <MastercardLogo />
                        {convertCardNumber(number)}
                      </CardNumber>
                      <CardName>{name}</CardName>
                    </CardWrapper>
                    <ButtonsWrapper>
                      <Button
                        color="orange"
                        type="button"
                        onClick={() => handleCardDetails(id)}
                      >
                        {t('profile.cardActions.detailsCard')}
                      </Button>
                      <Button
                        type="button"
                        onClick={() => handleRenameCard(id, providerType, name)}
                      >
                        {t('profile.cardActions.renameCard')}
                      </Button>
                      <Button
                        color="red"
                        type="button"
                        onClick={() => setCardIdToRemove(id)}
                      >
                        {t('profile.cardActions.removeCard')}
                      </Button>
                    </ButtonsWrapper>
                  </CardRow>
                )
              )}
            </>
          )}
          <WrapperAttachButtons>
            {isCryptoCardNotCreated && (
              <AttachCardButton
                type="button"
                disabled={clientValidate.fetching}
                onClick={handleCreateCard}
              >
                {t('profile.cryptoCardActions.createCryptoCard')}
              </AttachCardButton>
            )}
            {!isCryptoCardReleaseError && (
              <AttachCardButton
                type="button"
                onClick={() =>
                  window.open('https://wbitcash.com/services/kriptokarta')
                }
              >
                {t('profile.cardActions.moreDetails')}
              </AttachCardButton>
            )}
          </WrapperAttachButtons>
          {cardIdToRemove && (
            <DialogModal
              Component={Dialog}
              title={t('profile.cardActions.removeCardModalTitle')}
              message={t('profile.cardActions.removeCardModalDesc')}
              acceptButtonName={t('profile.cardActions.removeCard')}
              acceptFunc={() => deleteUserCard(cardIdToRemove)}
              onClose={() => setCardIdToRemove(null)}
              t={t}
            />
          )}
          {cardToRename.open && (
            <RenameCardModal
              t={t}
              value={cardName}
              onChange={e => setCardName(e.target.value)}
              onClose={() =>
                setCardToRename(prev => ({ ...prev, open: false }))
              }
              onSubmit={() => handleSetCardName()}
            />
          )}

          <ConfirmationForm
            t={t}
            isShowUserDetailsModal={isShowUserDetailsModal}
            setIsShowUserDetailsModal={setIsShowUserDetailsModal}
            setIsShowConsentForm={setIsShowConsentForm}
            isUserNotHavePhone={isUserNotHavePhone}
            isUserNotHaveGender={isUserNotHaveGender}
          />

          {isShowConsentForm && (
            <SimpleModal
              Component={ConsentForm}
              width="auto"
              t={t}
              onClose={() => setIsShowConsentForm(false)}
              handleOnSubmit={handleCheckInMsi}
              isLoading={isLoading}
            />
          )}

          {isLivenessCheck && (
            <SimpleModal
              isCheckMatch={isLivenessRegula}
              Component={isLivenessRegula ? FaceLiveness : LivenessCheck}
              onClose={() => setIsLivenessCheck(false)}
              handleOnSubmit={handleGetClientInfo}
              isLoading={isLoading}
              t={t}
              client={client}
            />
          )}

          {cryptoCardFrame.open && (
            <Modal
              Component={Iframe}
              onClose={() =>
                setCryptoCardFrame(prev => ({ ...prev, open: false }))
              }
              iframesrc={cryptoCardFrame.url}
              width="100%"
              height="100%"
              padding="50px"
              title="Crypto card release"
              noRoute
            />
          )}
        </CryptoCardWrapper>
      )}
    </>
  );
};

export default CryptoCardManagement;
