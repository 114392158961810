import { ContentTitle } from 'components/Verification/styled-ui';
import ErrorCommon from 'ui-kit/Error';
import styled from 'styled-components';
import device from 'device';
import checkbox from '../../assets/images/icons/check-blue.svg';

export const Error = styled(ErrorCommon)`
  position: absolute;
  bottom: 5px;
`;

export const Header = styled.div`
  font-size: 32px;
  color: #323233;
  line-height: 42px;
`;

export const HeaderBold = styled.div`
  font-size: 40px;
  padding-bottom: 30px;
  color: #323233;
  line-height: 50px;
  font-weight: bold;
`;

export const HeaderSmall = styled.div`
  padding-bottom: 30px;
  font-size: 32px;
  color: #323233;
  line-height: 42px;
`;

export const Info = styled.div`
  padding-top: 30px;
  color: #858585;
  font-size: 14px;
  line-height: 23px;

  a {
    color: ${props => props.theme.linkColor};
  }
`;
export const InfoSmall = styled.div`
  padding-bottom: 30px;
  color: #858585;
  font-size: 14px;
  line-height: 23px;
`;

export const Title = styled(ContentTitle)`
  padding: 72px 0 32px;
  font-weight: 500;
  font-size: 20px;
`;

export const WrapSelect = styled.div`
  position: relative;
`;

export const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 25px 35px 5px 10px;

  width: 100%;
  height: 82px;
  border: none;
  /*box-sizing: content-box;*/
  outline: none;
  background-color: #f1f3f5;

  color: #323233;
  font-size: 20px;
  text-indent: 8px !important;
`;

export const SupportWrapper = styled.div`
  z-index: 2;
  position: relative;
  max-width: 736px;
  margin: 0 auto;
  padding: 0 50px 70px;
  @media (max-width: 845px) {
    padding: 0 20px 70px;
    align-items: center;
  }
`;

export const FormWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(16, 58, 121, 0.15);
  border-radius: 12px;
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 764px;
`;

export const Row = styled.div`
  margin-bottom: ${({ clear }) => !clear && '40px'};
`;

export const FormUploadDocumentHelpText = styled.div`
  font-size: 13px;
  color: ${props => (props.error ? '#F92828' : props.theme.grayText)};
  @media ${device.laptop} {
    font-size: 10px;
  }
`;

export const FormContentSection = styled.div`
  font-size: 14px;
  line-height: 23px;
  color: #323233;
  padding-bottom: 20px;
`;

export const WrapInfo = styled.div`
  padding-top: 10px;
  font-size: 16px;
  color: ${props => props.theme.grayText};
  text-align: 'initial';
  padding-bottom: 30px;
  border-bottom: 1px solid #e2e2e2;
  @media ${device.laptop} {
    font-size: 12px;
  }
`;

export const Wrapper = styled.form`
  display: block;
  margin: 50px;
  width: 100%;
  @media ${device.laptop} {
    margin: 20px;
  }
`;

export const LabelCheckbox = styled.label`
  cursor: pointer;
  color: ${props => (props.error ? '#F92828' : props.theme.blackText)};
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  margin: 30px 0 40px 0;
`;

export const Checkbox = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid #c5c5c5;
  border-radius: 50%;
  display: inline-block;
  min-width: 24px;
  box-sizing: border-box;
  margin-right: 10px;
`;

export const HiddenInput = styled.input`
  display: none;
  :checked + ${Checkbox} {
    background: url(${checkbox}) no-repeat center;
    animation: animation-checkbox 0.4s ease-in-out;
    border: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  @keyframes animation-checkbox {
    0% {
      transform: scale(0.8);
    }
    70% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const LabelText = styled.span`
  color: ${props => props.theme.grayText};
  cursor: pointer;
  a {
    text-decoration: none;
  }
  text-align: ${props => props.textalign || 'inherit'};
  padding: ${props => props.padding || '0'};
`;
