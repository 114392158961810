import blueTheme from 'components/App/themes/blue';

export const stylesRegulaComponent = {
  fontFamily: 'Inter',
  onboardingScreenStartButtonBackground: blueTheme.blueBg,
  onboardingScreenStartButtonBackgroundHover: blueTheme.blueBg,
  retryScreenRetryButtonBackground: blueTheme.blueBg,
  retryScreenRetryButtonBackgroundHover: blueTheme.blueBg,
  cameraScreenSectorActive: blueTheme.blueBg,
  cameraScreenSectorTarget: blueTheme.btnLightColor,
  cameraScreenStrokeNormal: blueTheme.btnLightColor,
  processingScreenProgress: blueTheme.blueBg,
};

export const getBlob = async image => {
  if (!image) return null;
  const url = `data:image/jpeg;base64,${image}`;
  const res = await fetch(url);
  const blob = await res.blob();
  return blob;
};
