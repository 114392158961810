import device from 'device';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const RestrictWidth = styled.div`
  width: ${({ isMainPage }) => (isMainPage ? '65%' : '100%')};
  margin: 0 auto;
  background-color: ${({ theme }) => theme.fullWhite};
  box-shadow: 1px 1px 1px 0 #fff;
  @media ${device.laptopL} {
    width: ${({ isMainPage }) => isMainPage && '75%'};
  }
  @media ${device.laptop} {
    width: ${({ isMainPage }) => isMainPage && '100%'};
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMainPage }) =>
    isMainPage ? '1fr 1fr' : '1fr 1fr 1fr'};
  padding: ${({ isMainPage }) => (isMainPage ? '0' : '5%')};
  column-gap: 5%;
  row-gap: 50px;
  max-width: 3860px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.fullWhite};
  > div {
    z-index: 2;
    position: relative;
  }
  @media ${device.laptopL} {
    padding: 25px;
  }
  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${device.tablet} {
    grid-template-columns: 1fr;
    box-sizing: border-box;
    row-gap: 50px;
  }
  @media (max-width: 580px) {
    padding: ${({ isMainPage }) => (isMainPage ? '0px' : '25px')};
  }
`;

export const Media = styled.div``;

export const MediaHeader = styled.h4`
  font-size: 1.4rem;
  margin: 20px 0 15px;
  color: #1e1e28;
  font-weight: 500;
  @media ${device.laptop} {
    -webkit-text-size-adjust: none;
  }
`;

export const PhotoWrapper = styled.div`
  position: relative;
`;

export const AuthorImage = styled.img`
  position: absolute;
  bottom: 10%;
  left: 7%;
  z-index: 1;
  object-fit: contain;
  max-width: 30%;
  height: 25%;
  filter: brightness(0) invert(1);
  @media ${device.laptopL} {
    left: 6%;
  }
`;

export const BgImgWrapper = styled.div`
  max-width: ${({ isMainPage }) => (isMainPage ? '510px' : 'inherit')};
  position: relative;
  width: 100%;
  :after {
    content: '';
    display: block;
    padding-bottom: 50%;
  }
`;

export const BgContainer = styled.div`
  position: relative;
  width: 100%;
  :after {
    content: '';
    display: block;
    padding-bottom: 50%;
  }
`;

export const BackgroundImage = styled.img`
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const MainBlock = styled.h2`
  margin: 110px 0 70px 0;
  display: flex;
  text-align: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 2.7rem;
  color: ${props => props.theme.blackText};
  @media ${device.desktop1280} {
    font-size: 2.1rem;
  }
  @media ${device.mobile920} {
    font-size: 2.2rem;
  }
  @media ${device.laptopL} {
    text-align: center;
    margin: 65px 0 65px 0;
  }
  @media ${device.tablet} {
    text-align: left;
    margin: 50px 0 50px 25px;
  }
`;

export const WrapperSlider = styled.div`
  margin: 0 auto;
  display: none;
  @media (max-width: 580px) {
    display: block;
  }
`;

export const MediaBlock = styled.div`
  width: 100%;
  margin: 0 auto;
  display: block;
  @media (max-width: 580px) {
    display: ${({ isMainPage }) => (isMainPage ? 'none' : 'block')};
  }
`;

export const DatePublished = styled.div`
  color: #8f9db1;
  font-size: 1.1rem;
  -webkit-text-size-adjust: none;
`;

export const MediaLink = styled(Link)`
  flex: 1 0 40%;
  @media (max-width: 580px) {
    flex: ${({ $isMainPage }) => ($isMainPage ? '1 0 40%' : '1 0 100%')};
  }
  -webkit-text-size-adjust: none;
  text-decoration: none;
`;

export const LinkAnotherPosts = styled(Link)`
  margin-top: 70px;
  color: #0069ff;
  font-size: 1.1rem;
  display: block;
  text-align: center;
  @media ${device.laptopL} {
    margin-top: 65px;
  }
  @media ${device.laptop} {
    margin-top: 50px;
  }
  @media (max-width: 580px) {
    display: none;
  }
  -webkit-text-size-adjust: none;
  text-decoration: none;
`;

export const MediaLoader = styled.div`
  padding: 25px 0 65px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
