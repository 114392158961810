import { ReactComponent as RefreshWhiteIcon } from 'assets/images/icons/refresh-white.svg';
import { ReactComponent as Check } from 'assets/images/icons/check-blue.svg';
import { ReactComponent as CancelIcon } from 'assets/images/icons/cancel.svg';
import { ReactComponent as SignIn } from 'assets/images/icons/signin-light.svg';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import device from 'device';

export const ProcessingGrid = styled.div`
  box-sizing: border-box;
  margin-bottom: 153px;
  padding: 40px 40px 0 40px;
  background: #fff;
  border-radius: 8px;
  @media ${device.tablet} {
    padding: 25px 20px 0 20px;
    margin-bottom: 10px;
  }
  @media ${device.mobileL} {
    padding: 25px 10px 0 10px;
    margin-bottom: 10px;
  }
`;

export const Submit = styled.div`
  position: relative;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 40px;
  @media ${device.tablet} {
    padding: 10px 10px 40px 10px;
  }
`;

export const Title = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  padding-bottom: 10px;
`;

export const BlockTitle = styled.div`
  color: ${props => props.color || props.theme.blackText};
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  @media ${device.tablet} {
    font-size: 24px;
    line-height: 30px;
    padding: 30px 0 30px 10px;
  }
  padding: 30px 0 30px 30px;
  margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
`;

export const BlockTitleUpdatedTerms = styled.div`
  color: ${props => props.color || props.theme.blackText};
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 34px;
  @media ${device.tablet} {
    font-size: 1.2rem;
    line-height: 24px;
  }
  padding: 40px 0 10px 0;
  margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
`;

export const StyledProcessingStatus = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: ${({ noMargin }) => (noMargin ? '0' : '30px')};
`;

export const Update = styled.div`
  display: flex;
  color: ${props => props.theme.linkColor};
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 22px;
  padding-left: 10px;
`;

export const UpdateTextButton = styled(Update)`
  color: white;
  font-size: 18px;
  line-height: 22px;
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const styles = css`
  animation: ${rotate} 2s linear infinite;
`;

export const Wrapper = styled.div`
  cursor: ${({ noCursor }) => !noCursor && 'pointer'};
  display: flex;
  text-decoratsion: none;
  justify-content: left;
`;

export const WrapperLink = styled(Link)`
  text-decoration: none;
`;

export const StatusGrid = styled.div`
  display: grid;
  grid-template-columns: 80px 2px 1fr;
  grid-column-gap: 40px;
  grid-column: 1/-1;
  padding-left: 30px;
  @media ${device.tablet} {
    grid-auto-rows: auto;
    grid-column-gap: 20px;
    padding-left: 10px;
  }
`;

export const Time = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 18px;
  line-height: 22px;
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const WrappedDate = styled.div`
  display: flex;
  flex-direction: column;
  ${Time}:first-child {
    padding-top: 30px;
  }
`;

export const StatusDate = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 18px;
  line-height: 22px;
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }
  padding-top: 7px;
`;

export const Status = styled(Time)`
  color: ${props => props.theme.blackText};
  font-size: 18px;
  line-height: 22px;
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const StatusInfo = styled.div`
  ${Status}:first-child {
    padding-top: 30px;
  }
`;

export const Description = styled(StatusDate)`
  color: ${props => props.theme.grayText};
  font-size: 18px;
  line-height: 22px;
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }
  padding-top: 7px;
`;

export const Border = styled.div`
  background-color: #e5ebf1;
  :nth-last-child(-n + 2) {
    height: 70px;
  }
`;

export const Checked = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  box-sizing: border-box;
`;

export const Shape = styled(Check)`
  border-radius: 50%;
  position: absolute;
  circle {
    ${({ status }) => {
      switch (status) {
        case 'Завершена':
          return css`
            fill: #33a852;
          `;
        default:
          return css`
            fill: #0069ff;
          `;
      }
    }}
  }
`;

export const Cancel = styled(CancelIcon)`
  border-radius: 50%;
  position: absolute;
`;

export const StyledRefreshWhiteIcon = styled(RefreshWhiteIcon)`
  ${({ fetching }) => (fetching === 'true' ? styles : 'none')}
`;

export const InfoRequestWrapper = styled.div`
  padding: 0 0 45px 30px;
  @media ${device.tablet} {
    font-size: 24px;
    padding: 0 25px 30px 25px;
  }
  @media ${device.mobileL} {
    padding: 0 10px 15px 10px;
  }
`;

export const ProcessingWrapper = styled.div`
  z-index: 2;
  max-width: 926px;
  margin: 0 auto;
  position: relative;
`;

export const ErrorLabel = styled.div`
  display: flex;
  grid-column: 1 / -1;
  align-items: center;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #ffe3e3;
  font-size: 14px;
  line-height: 17px;
  @media ${device.tablet} {
    font-size: 13px;
    line-height: 16px;
  }
  color: #f92828;
  box-sizing: border-box;
  height: 50px;
  @media ${device.tablet} {
    margin: 20px 0 0 0;
    height: 80px;
  }
`;

export const SystemErrorLabel = styled.div`
  display: flex;
  grid-column: 1 / -1;
  align-items: center;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #c7d0ff;
  font-size: 14px;
  line-height: 17px;
  @media ${device.tablet} {
    font-size: 13px;
    line-height: 16px;
  }
  color: #1b39ce;
  box-sizing: border-box;
  height: 50px;
  @media ${device.tablet} {
    margin: 20px 0 0 0;
    height: 80px;
  }
`;

export const ChangedOrderWarning = styled.div`
  color: ${props => props.theme.linkColor};
  font-size: 16px;
  line-height: 19px;
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 17px;
    padding: 0;
  }
  text-align: center;
  grid-column: 1 / -1;
  padding: 0 90px 15px 90px;
`;

export const ActionButtonWrapper = styled.div`
  padding: 40px 90px 30px 90px;
  display: flex;
  grid-column: 1 / -1;
  grid-gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 20px;
  @media ${device.tablet} {
    padding: 0 10px;
    flex-direction: column;
  }
`;

export const OrderTimerChangeApprove = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0;
  justify-content: space-between;
  display: inline-block;
  padding: 0 0 0 30px;
  @media ${device.tablet} {
    padding: 10px 0 0 0;
    font-size: 13px;
    line-height: 17px;
  }
`;

export const LowTextInfo = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 14px;
  line-height: 17px;
  @media ${device.tablet} {
    font-size: 13px;
    line-height: 16px;
    padding-bottom: 30px;
  }
  text-align: center;
  padding-top: 25px;
`;

export const EmptyBlock = styled.div`
  height: 30px;
`;

export const DownloadIcon = styled(SignIn)`
  transform: rotate(90deg);
`;

export const BankAccountValue = styled.div`
  font-family: 'Inter';
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }
  padding: 0 10px;
`;

export const WrapperBankAccount = styled.div`
  position: relative;
  padding-left: 30px;
  @media ${device.tablet} {
    padding: 0 10px;
  }
`;
