/* eslint-disable no-useless-escape */
import { isCrypto } from 'utils/crypto';
import { CRYPTO_CURRENCY_REG_EXP, FIAT_CURRENCY_REG_EXP } from 'utils/regexp';

const intl = new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2 });
export const localeFlatNumber = value => intl.format(value);

const intlCrypto = new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 8 });
export const localeCryptoNumber = value => intlCrypto.format(value);

const maxIntegerForCurrency = {
  BTC: 99,
  ETH: 999,
  USDT: 9999999,
  USDT_TRC: 9999999,
  TRX: 999999,
  TON: 999999,
  USDT_TON: 9999999,
  WBP: 9999999,
  BYN: 9999999,
  EUR: 9999999,
  USD: 9999999,
  RUB: 999999999,
};

export const maxFullValueForCurrency = {
  BTC: '99.99999999',
  ETH: '999.99999999',
  USDT: '9999999.999999',
  USDT_TRC: '9999999.999999',
  TRX: '999999.999999',
  TON: '999999.99999999',
  USDT_TON: '9999999.999999',
  WBP: '9999999.999999',
  BYN: '9999999.99',
  EUR: '9999999.99',
  USD: '9999999.99',
  RUB: '999999999.99',
};

export const localeNumber = (value, type) => {
  const valueAmount = parseFloat(value);
  if (Number.isNaN(valueAmount)) {
    return value;
  }

  if (isCrypto(type)) {
    return localeCryptoNumber(valueAmount);
  }
  return localeFlatNumber(valueAmount);
};

export const getValidIntegerPartOfNumber = (value, type) => {
  const [intPart] = value?.split(/[,.]/);

  return parseInt(intPart, 10) > maxIntegerForCurrency[type];
};

export const getValidFractionalPartOfNumber = (value, type) => {
  const currentValue = value?.replace(/,/g, '.')?.split('.')[1];
  const maxFractionalValue = maxFullValueForCurrency[type]?.split('.')[1];

  return currentValue?.length > maxFractionalValue?.length;
};

export const localeNumberFromCustomNumberConverter = (value, type) => {
  const valueAmount = parseFloat(value);
  if (Number.isNaN(valueAmount) || value.toString().trim() === '') {
    return { inputValue: value };
  }

  const cutFloatRegExp = isCrypto(type)
    ? CRYPTO_CURRENCY_REG_EXP
    : FIAT_CURRENCY_REG_EXP;

  const cutFloatPart = value
    .replace(cutFloatRegExp, '$1')
    .replace(/^0*(\d)/g, '$1')
    .replace(/\s/g, '');

  if (typeof value === 'number') {
    return { inputValue: cutFloatPart };
  }

  if (
    getValidIntegerPartOfNumber(value, type) ||
    getValidFractionalPartOfNumber(value, type)
  ) {
    return {
      inputValue: value,
      isInputLimit: true,
    };
  }
  return { inputValue: cutFloatPart };
};

export const localeNumberFromCustomNumber = (value, type) => {
  if (value?.toString().trim() === '') {
    return value;
  }
  if (typeof value === 'number') {
    return localeNumber(value, type);
  }
  const formatValue = value?.replace(/\s/g, '').replace(/,/g, '.');
  return localeNumber(formatValue, type);
};

export const localeNumberFromOutputNumber = (value, type) => {
  if (value === 0) {
    return '0';
  }
  return localeNumberFromCustomNumber(value, type);
};

export const fromLocaleNumber = value => {
  if (!value) {
    return 0;
  }
  const formatValue = value
    .toString()
    .replace(/\s/g, '')
    .replace(/,/g, '.');
  return parseFloat(formatValue);
};

export const convertExponentialToDecimal = exponentialNumber => {
  const str = exponentialNumber.toString();
  if (str.indexOf('e') !== -1) {
    const exponent = parseInt(str.split('-')[1], 10);
    return exponentialNumber.toFixed(exponent);
  }
  return exponentialNumber;
};

export const convertCardNumber = (cardNumber = '') => {
  const dot = '•';
  const str = (dot.repeat(12) + cardNumber?.slice(-4))
    ?.match(/.{1,4}/g)
    ?.join(' ');
  return str;
};

export const numberWithSpaces = value => {
  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join(',');
};

export const getCardsCommissionByProvider = (commissions, provider) => {
  return commissions?.data?.length
    ? commissions?.data?.find(({ paymentType }) => paymentType === provider)
    : {};
};
