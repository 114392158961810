import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'ui-kit/Button';
import device from 'device';
import { ReactComponent as CloseEyeImage } from 'assets/images/icons/close-eye.svg';
import { ReactComponent as OpenEyeImage } from 'assets/images/icons/open-eye.svg';
import { ReactComponent as MailComponent } from 'assets/images/mail.svg';
import { ReactComponent as MfaComponent } from 'assets/images/mfa.svg';
import { ReactComponent as InfoComponent } from 'assets/images/icons/info.svg';

export const FormGrid = styled.form`
  display: grid;
  grid-row-gap: ${props => (props.twoFaFlag ? '0' : '35px')};
  grid-column: 1/-1;
  margin-top: 35px;
  @media ${device.laptop} {
    grid-template-columns: none;
  }
  @media ${device.mobile920} {
    margin-top: 25px;
  }
`;

export const Header = styled.div`
  color: ${props => props.theme.blackText};
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  @media ${device.laptop} {
    font-size: 24px;
    line-height: 30px;
  }
  padding-bottom: ${props => (props.padding ? `${props.padding}px` : '40px')};
  text-align: ${props => props.textalign || 'inherit'};
  @media ${device.mobile920} {
    padding-bottom: 25px;
  }
`;

export const Description = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 16px;
  white-space: pre-line;
  text-align: inherit;
  padding: 0;
  line-height: 24px;
  @media ${device.laptop} {
    font-size: 13px;
    line-height: 20px;
  }
  padding-bottom: 20px;
  text-align: ${props => props.textalign || 'inherit'};
`;

export const DescriptionLink = styled(Link)`
  color: ${props => props.theme.grayText};
  padding-top: 15px;
  font-size: 16px;
  line-height: 19px;
  @media ${device.laptop} {
    font-size: 12px;
    line-height: 16px;
  }
  text-decoration: none;
  text-align: ${props => props.textalign || 'inherit'};
`;

export const Adding = styled.span`
  color: ${props => props.theme.linkColor};
  font-size: 16px;
  line-height: 19px;
  @media ${device.tablet} {
    font-size: 12px;
    line-height: 15px;
  }
  cursor: pointer;
`;

export const Info = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 16px;
  line-height: 19px;
  @media ${device.tablet} {
    font-size: 12px;
    line-height: 15px;
  }
  padding-top: 24px;
  text-align: left;
  a {
    text-decoration: none;
  }
`;

export const InfoPartner = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 24px;
  @media ${device.tablet} {
    font-size: 12px;
    line-height: 15px;
  }
  text-align: left;
  a {
    text-decoration: none;
  }
`;

export const OpenEyeIcon = styled(OpenEyeImage)`
  cursor: pointer;
`;

export const InfoIcon = styled(InfoComponent)`
  cursor: pointer;
`;

export const AppInfo = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 0.9rem;
  line-height: 24px;
  white-space: pre-line;
  @media ${device.laptop} {
    line-height: 20px;
  }
  a {
    color: ${props => props.theme.linkColor};
    text-decoration: none;
  }
  text-align: ${props => props.textalign || 'inherit'};
  padding: ${props => props.padding || '0'};
`;

export const InnerLink = styled.div`
  display: none;
  position: absolute;
  width: 348px;
  height: max-content;
  color: #aeb7c6;
  left: 77%;
  top: 47%;
  text-align: center;
  font-size: 14px;
  background: #ffffff;
  padding: 5px 5px;
  box-shadow: 0px 6px 20px rgba(16, 58, 121, 0.1);
  border-radius: 10px;
  @media ${device.laptop} {
    margin: 25px;
    width: fit-content;
    font-size: 12px;
    left: 0;
    top: 46%;
  }
`;

export const SupportLinkInline = styled(Link)`
  color: ${props => props.theme.linkColor};
  font-family: Inter;
  cursor: pointer;
  margin-left: 7px;
  font-size: 16px;
  line-height: 22px;
  @media ${device.laptop} {
    font-size: 12px;
    line-height: 17px;
  }
  a {
    text-decoration: none;
  }
  text-align: ${props => props.textalign || 'inherit'};
  padding: ${props => props.padding || '0'};
`;

export const WrapperLink = styled.button`
  cursor: pointer;
  &:hover {
    ${InnerLink} {
      display: block;
    }
  }
`;

export const AppInfoBlock = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 16px;
  line-height: 22px;
  @media ${device.laptop} {
    font-size: 12px;
    line-height: 17px;
  }
  a {
    text-decoration: none;
  }
  text-align: ${props => props.textalign || 'inherit'};
  padding: ${props => props.padding || '0'};
`;

export const AddingBlack = styled.span`
  color: #000000;
  font-size: 16px;
  line-height: 22px;
  @media ${device.laptop} {
    font-size: 12px;
    line-height: 17px;
  }
  text-align: ${props => props.textalign || 'inherit'};
`;

export const Wrapped = styled.div`
  text-align: center;
  padding: 20px 0 20px 0;
  min-height: 28px;
  @media ${device.mobileL} {
    min-height: 24px;
  }
`;

export const QRCode = styled.div`
  height: 160px;
  width: 160px;
  margin: 0 auto;
  @media ${device.laptop} {
    height: 115px;
    width: 115px;
  }
  display: flex;
  justify-content: center;
`;

export const ButtonGrid = styled.div`
  display: grid;
  grid-row-gap: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ResendEmail = styled.button`
  color: ${props => props.theme.linkColor};
  font-size: 16px;
  line-height: 24px;
  @media ${device.laptop} {
    font-size: 13px;
    line-height: 23px;
  }
  justify-content: center;
  cursor: pointer;
`;

export const WrapperTimer = styled.div`
  padding-top: 24px;
  line-height: 32px;
  text-align: center;
`;

export const ResendTimer = styled.p`
  color: ${props => props.theme.grayText};
  font-size: 16px;
  line-height: 24px;
  @media ${device.laptop} {
    font-size: 13px;
    line-height: 23px;
  }
  margin: 0;
  justify-content: center;
`;

export const WrapperResend = styled.div`
  padding-top: 24px;
  line-height: 32px;
  text-align: center;
`;

export const Agreements = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 13px;
  line-height: 17px;
  @media ${device.laptop} {
    font-size: 12px;
    line-height: 16px;
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.linkColor};
  }
`;
export const WrapEye = styled.div`
  position: absolute;
  top: 18px;
  right: 16px;
  z-index: 1;
`;

export const CloseEyeIcon = styled(CloseEyeImage)`
  cursor: pointer;
`;

export const Secret = styled.input`
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  flex-grow: 2;
  border: none;
  width: 250px;
  padding: 0 8px 0 20px;
  @media ${device.mobileL} {
    width: 220px;
    font-size: 14px;
    line-height: 24px;
  }
  @media ${device.mobileS} {
    width: 185px;
    font-size: 13px;
    line-height: 24px;
  }
  @media (max-width: 280px) {
    width: 154px;
    font-size: 13px;
    line-height: 24px;
  }
`;

export const LineInfo = styled.span`
  color: ${props => props.theme.grayText};
  font-size: 16px;
  line-height: 19px;
  @media ${device.tablet} {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const InfoForgotPassword = styled(Info)`
  span {
    color: ${props => props.theme.grayText};
    font-size: 16px;
    line-height: 19px;
  }
  @media ${device.laptop} {
    margin-top: -21px;
    span {
      font-size: 12px;
      line-height: 15px;
    }
  }
  text-align: right;
  margin-top: -30px;
  padding-top: 0;
`;

export const RestorePasswordLabel = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 16px;
  line-height: 24px;
  @media ${device.laptop} {
    font-size: 13px;
    line-height: 23px;
  }
  padding-bottom: 20px;
  text-align: ${props => props.textalign || 'inherit'};
`;

export const ButtonWrapper = styled.div`
  height: 58px;
  width: 100%;
`;

export const ConfirmLink = styled(Link)`
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.btnColor};
  color: #ffffff;
  border-radius: 5px;
`;

export const ConfirmButton = styled.button`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.btnColor};
  color: #ffffff;
  width: 100%;
  font-size: 1rem;
  border-radius: 5px;
`;

export const ConfirmEmailLabel = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 16px;
  line-height: 24px;
  @media ${device.laptop} {
    font-size: 13px;
    line-height: 23px;
  }
  padding-bottom: 20px;
  text-align: ${props => props.textalign || 'inherit'};
`;

export const ButtonWrapperConfirm = styled.div`
  margin: 0 auto;
  height: 58px;
  width: 40%;
  @media ${device.laptop} {
    width: 95%;
  }
`;

export const WrapperConfirm = styled.div`
  background: 0% 0% / cover ${({ theme }) => theme.blueBg};
  min-height: 100vh;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
  z-index: 99999;
  position: ${({ noRoute }) => noRoute && 'fixed'};
  background: ${({ noRoute }) => noRoute && 'rgba(247, 247, 248, 0.9)'};
  height: ${({ noRoute }) => noRoute && '100vh'};
  overflow-y: auto;
  @media (max-width: 845px) {
    padding: 134px 50px 50px 50px;
  }
  @media (max-width: 509px) {
    padding: 70px 15px 100px 15px;
  }
`;

export const SmallModalConfirm = styled.div`
  position: relative;
  z-index: 10;
  background: white;
  border-radius: 10px;
  width: 557px;
  padding: 32px 60px;
  box-shadow: 0px 10px 30px rgba(16, 58, 121, 0.15);
  box-sizing: border-box;

  @media (max-width: 557px) {
    width: inherit;
    max-width: 557px;
  }
`;

export const EmailSentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmailConfirmedWrapper = styled(MailComponent)`
  width: 200px;
  height: 200px;
`;

export const ShowMfaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MfaWrapper = styled(MfaComponent)`
  height: 160px;
  @media ${device.laptop} {
    height: 115px;
  }
  align-items: center;
`;

export const SecretWrapper = styled.div`
  display: flex;
  align-items: center;
  width: min-content;
  margin: 0 auto;
`;

export const WebMoneyButton = styled(Button)`
  gap: 8px;
  padding: 6px 12px;
  color: ${({ theme }) => theme.btnColor};
`;
