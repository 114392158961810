import styled from 'styled-components';
import { Accordion } from 'react-accessible-accordion';
import device from 'device';

export const AccordionWrapper = styled(Accordion)`
  margin-bottom: 50px;
  @media ${device.laptop} {
    margin-bottom: 25px;
  }
`;

export const QuestionCategory = styled.div`
  padding: 0 0 20px 25px;
  color: ${props => props.theme.fullWhite};
  font-size: 28px;
  @media ${device.laptop} {
    font-size: 16px;
    padding: 0 0 20px 25px;
  }
`;

export const FaqWrapper = styled.div`
  padding: 0 50px 50px;
  max-width: 850px;
  margin: 0 auto;
  margin-bottom: 80px;
  > div {
    z-index: 2;
    position: relative;
  }
  @media ${device.laptop} {
    padding: 0 25px 25px;
  }
  @media ${device.mobileL} {
    padding: 0 15px 25px;
  }
`;

export const LoadWrapper = styled.div`
  padding: 20px 0;

  > div {
    margin: 0 auto;
  }
`;
