import styled from 'styled-components';
import device from 'device';

export const HomeContainer = styled.div`
  position: relative;
  background-color: ${props => props.theme.blueBg};
  background-size: cover;
  padding-bottom: 1px;
  min-height: 724px;
  @media (max-width: 845px) {
    padding: 0;
    padding-bottom: 25px;
  }
  @media (max-width: 480px) {
    padding-bottom: ${({ isOrderView }) => isOrderView && '10px'}};
  }
`;

export const DashboardContainer = styled.div`
  background-color: ${props => props.theme.blueBg};
  margin: 0 auto;
`;

export const NotFoundContainer = styled.div`
  margin: 0 auto;
  position: absolute;
  min-height: 100vh;
  width: 100%;
  background-color: ${props => props.theme.blueBg};
  background-size: cover;
`;

export const InsideWrapperNotFoundContainer = styled.div``;

export const WrapHeader = styled.div`
  background: ${props => props.theme.blueBg};
  background-size: cover;
  @media (max-width: 845px) {
    padding: 0;
  }
`;

export const WrapContent = styled.div`
  flex: 1;
`;

export const Page = styled.h1`
  max-width: 914px;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  color: ${props => props.theme.fullWhite};
  margin: 25px auto;
  padding: 0 15px;
  @media ${device.laptop} {
    font-size: 2rem;
    font-weight: 500;
  }
`;
export const Wrapper = styled.div`
  text-align: center;
  max-height: 204px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Description = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
`;

export const WrapperPage = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;
