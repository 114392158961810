import styled, { css } from 'styled-components';
import device from 'device';
import { Link } from 'react-router-dom';

import { ReactComponent as CardSvg } from 'assets/images/payments/card.svg';
import { ReactComponent as SettlementSvg } from 'assets/images/payments/settlement.svg';
import { ReactComponent as Mastercard } from 'assets/images/cards/masterCard.svg';
import { ReactComponent as Visa } from 'assets/images/cards/visa.svg';
import { ReactComponent as CryptoCardSvg } from 'assets/images/cards/cryptoCard.svg';
import { ReactComponent as EripSvg } from 'assets/images/payments/erip.svg';
import { ReactComponent as ErrorSvg } from 'assets/images/icons/error.svg';
import { ReactComponent as IconInfo } from 'assets/images/icons/info.svg';
import { ReactComponent as BelCardSvg } from 'assets/images/cards/belcard.svg';
import { ReactComponent as MirSvg } from 'assets/images/cards/mir.svg';
import { ReactComponent as AlfaSvg } from 'assets/images/icons/alfa-bank.svg';
import { ReactComponent as BelarusbankSvg } from 'assets/images/icons/belarusbank.svg';

const CARD_PADDING = 10;
const PAY_METHOD_PADDING = 20;
const PAY_METHOD_PADDING_MOBILE = 15;

export const SettlementLogo = styled(SettlementSvg)`
  width: 50px;
  height: 35px;
`;

export const CryptoCardLogo = styled(CryptoCardSvg)`
  width: 50px;
  height: 30px;
  border-radius: 3px;
  @media ${device.tablet} {
    height: 25px;
  }
`;

export const EripLogo = styled(EripSvg)`
  width: 50px;
  height: 30px;
`;

export const AlfaLogo = styled(AlfaSvg)`
  width: 40px;
  height: 40px;
`;
export const BelarusbankLogo = styled(BelarusbankSvg)`
  width: 40px;
  height: 40px;
`;

export const CardLogo = styled(CardSvg)`
  width: 50px;
  height: 35px;
`;

export const MastercardLogo = styled(Mastercard)`
  zoom: 1.2;
  @media ${device.mobileL} {
    zoom: 1;
  }
`;

export const VisaLogo = styled(Visa)`
  zoom: 1.2;
  @media ${device.mobileL} {
    zoom: 1;
  }
`;

export const BelCardLogo = styled(BelCardSvg)`
  zoom: 1.2;
  @media ${device.mobileL} {
    zoom: 1;
  }
`;
export const MirLogo = styled(MirSvg)`
  zoom: 1.2;
  @media ${device.mobileL} {
    zoom: 1;
  }
`;

export const ErrorIcon = styled(ErrorSvg)`
  cursor: pointer;
`;

export const PaymentListWrapper = styled.div`
  z-index: 2;
  position: relative;
  max-width: 920px;
  margin: 0 auto;
  > ul {
    fonst-size: 14px;
    color: ${({ theme }) => theme.grayText};
    li {
      white-space: wrap;
    }
  }
`;

export const PaymentWrapper = styled.div`
  position: relative;
  border: ${({ isRecommended, theme }) =>
    `1px solid ${isRecommended ? theme.green : theme.borderCard}`};
  border-radius: 14px;
  display: flex;
  padding: ${PAY_METHOD_PADDING}px;
  background-color: ${({ theme, isRecommended }) =>
    isRecommended ? theme.fullWhite : 'rgba(252, 252, 252, 1)'};
  margin-bottom: 20px;
  > :first-child {
    min-width: 50px;
  }
  @media ${device.tablet} {
    padding: ${PAY_METHOD_PADDING_MOBILE}px;
    > svg:first-child {
      min-width: 35px;
      margin-right: 15px;
    }
  }
`;

export const MainPaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  @media ${device.mobileL} {
    align-items: flex-start;
    flex-direction: column;
  }

  > h4 {
    color: ${({ theme }) => theme.fullBlackText};
    margin: 0;
  }
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.grayText};
  margin: 10px 0 20px 0;
  line-height: 140%;
  white-space: pre-line;
`;

export const RecommendLabel = styled.div`
  font-weight: 400;
  display: ${({ isRecommended }) => !isRecommended && 'none'};
  margin-left: 15px;
  width: min-content;
  background-color: ${({ theme }) => theme.green};
  padding: 5px 10px;
  color: ${({ theme }) => theme.fullWhite};
  font-size: 0.8rem;
  border-radius: 8px;

  @media ${device.mobileL} {
    margin-top: 5px;
    margin-left: 0;
  }
`;

export const CardLabel = styled.h6`
  font-size: 1rem;
  font-weight: 400;
  margin: 20px 0 0;
  background: ${({ theme }) => theme.grayBg};
  padding: 10px;
  border-radius: 5px;
  color: ${({ theme }) => theme.fullBlackText};
  transition: all 0.3s;
`;

export const AttachButtonWrapper = styled.div`
  text-align: right;
  margin-bottom: 10px;
`;

export const AttachCardButton = styled.button`
  font-size: 16px;
  color: ${({ theme }) => theme.btnLightColor};
  padding: 10px;
  border: none;
  font-family: 'Inter';
  cursor: pointer;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.grayBg};
  border-radius: 8px;
  transition: all 0.3s;
  &:disabled {
    color: ${({ theme }) => theme.selectHoverColor};
    cursor: initial;
  }
  &:hover {
    color: ${({ theme }) => theme.grayBg};
    background: ${({ theme }) => theme.btnLightColor};
  }
`;

export const AttachSettlement = styled(Link)`
  font-size: 1rem;
  color: ${props => props.theme.btnLightColor};
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin-top: ${({ margintop }) => margintop};
  @media ${device.tablet} {
    margin-top: 0;
  }
`;

export const CardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: ${CARD_PADDING}px 5px;
  margin: 10px 0;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  &:hover {
    background-color: ${({ theme }) => theme.grayBg};

    div > button {
      visibility: initial;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const CryptoCardWrapper = styled.div``;

export const LoadWrapper = styled.div`
  height: 30px;
  margin-bottom: 10px;
  text-align: center;
`;

export const LinkText = styled(Link)`
  color: ${props => props.theme.btnLightColor};
  cursor: pointer;
  text-decoration: none;
`;

export const ReloadButton = styled.span`
  color: ${props => props.theme.btnLightColor};
  cursor: pointer;
  transition: text-decoration 0.2s;
  &:hover {
    text-decoration: underline dotted;
  }
`;

export const CardLoadError = styled.div`
  width: fit-content;
  padding: 5px 10px;
  border: 1px solid ${({ theme }) => theme.inputErrorColor};
  box-sizing: border-box;
  margin: ${({ margin }) => margin || '0 auto'};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.borderErrorColor};
  color: ${({ theme }) => theme.inputErrorColor};
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 0;
  margin-bottom: 10px;
  text-indent: 10px;
  font-size: 16px;
  background: ${props => props.theme.fullWhite};
  border: ${({ isError, theme }) =>
    `1px solid ${isError ? theme.notificatonErrorColor : theme.darkgray}`};
  notificatonerrorcolorborder-radius: 6px;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const CardIcon = styled.div`
  width: 50px;
  text-align: center;
  @media ${device.mobileL} {
    text-align: left;
  }
`;

export const CardNumber = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.94rem;
  letter-spacing: 0.001em;
  white-space: pre-line;
  word-break: break-word;
  line-height: 15px;
  > svg {
    margin-right: ${CARD_PADDING}px;
  }
  @media ${device.tablet} {
    font-size: 0.86rem;
  }
  @media ${device.mobileL} {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const CardName = styled.div`
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  color: ${props => props.theme.fullBlackText};

  @media ${device.laptopL} {
    white-space: pre-line;
    word-break: break-word;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
  }
`;

export const Button = styled.button`
  ${({ color }) => {
    switch (color) {
      case 'orange':
        return css`
          color: ${props => props.theme.orange};
        `;
      case 'red':
        return css`
          color: ${props => props.theme.errorColor};
        `;
      default:
        return css`
          color: ${props => props.theme.fullBlackText};
        `;
    }
  }}
  font-family: 'Inter';
  padding: 0;
  font-weight: 300;
  font-size: 14px;
  visibility: hidden;
  cursor: pointer;
  white-space: nowrap;

  &:nth-child(2) {
    margin: 0 10px;
  }

  @media ${device.laptopL} {
    visibility: visible;
  }

  @media ${device.tablet} {
    white-space: normal;
    margin: 0;
    visibility: visible;

    &:nth-child(2) {
      margin: 10px 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const WrapperAttachButtons = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  margin-top: 20px;

  > button:first-child {
    margin-right: 0;
    margin-bottom: 10px;
  }

  @media ${device.desktop} {
    flex-direction: row;
    align-items: center;

    > button:first-child {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }

  @media ${device.tablet} {
    align-items: start;
    justify-content: start;
    flex-direction: column;
    margin-top: 10px;

    > button:first-child {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  @media ${device.mobileL} {
    > button:first-child {
      text-align: left;
      white-space: pre-line;
    }
  }
`;

export const CardsWrapper = styled.div`
  border: ${({ theme }) => `1px solid ${theme.borderCard}`};
  background-color: ${({ theme }) => theme.fullWhite};
  border-radius: 14px;
  padding: 15px;
  margin-bottom: 20px;
  flex: 1;
`;

export const TypesCardWrapper = styled.div`
  font-size: 16px;
  margin-bottom: 25px;
`;

export const CardsTitleWrapper = styled.div`
  margin-bottom: 10px;
`;

export const CardsInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
  > ul {
    padding-left: 20px;
  }
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const CardsInfoList = styled.ul`
  padding-left: 35px;
  @media ${device.tablet} {
    padding-left: 10px;
  }
`;

export const CardsInfoItem = styled.li`
  white-space: nowrap;
  padding-left: 5px;
  &::marker {
    content: '✓';
    color: ${({ theme }) => theme.btnLightColor};
  }
  @media ${device.tablet} {
    white-space: pre-line;
    font-size: 14px;
  }
`;

export const CardsInfoBlock = styled.div`
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    margin-bottom: 20px;
  }
`;

export const InfoIcon = styled(IconInfo)`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

export const InnerHover = styled.div`
  white-space: pre-line;
  display: none;
  position: absolute;
  width: 410px;
  height: max-content;
  color: ${({ theme }) => theme.grayText};
  text-align: left;
  font-size: 14px;
  background: ${({ theme }) => theme.fullWhite};
  padding: 10px;
  box-shadow: 0 6px 20px rgba(16, 58, 121, 0.1);
  border-radius: 8px;
  z-index: 1;
  @media ${device.laptop} {
    margin: 10px;
    width: fit-content;
    font-size: 12px;
    left: 0;
  }
`;

export const WrapperHover = styled.button`
  cursor: pointer;
  padding: 0;
  margin-left: 20px;
  &:hover {
    ${InnerHover} {
      display: block;
    }
  }
  @media ${device.tablet} {
    margin-left: 5px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 15px;
  }
`;

export const Header = styled.div`
  color: ${props => props.theme.blackText};
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  @media ${device.laptop} {
    font-size: 24px;
    line-height: 30px;
  }
  padding: 40px 0;
  text-align: center;
  @media ${device.mobile920} {
    padding-bottom: 25px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  button {
    height: 56px;
    margin-bottom: 20px;
  }
`;

export const FormWrapper = styled.div`
  width: 557px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media ${device.mobile920} {
    width: auto;
  }
`;

export const LogoWrapper = styled.div`
  cursor: pointer;
  > img {
    height: 60px;
    @media ${device.desktop} {
      height: 52px;
    }
    @media ${device.laptopXL} {
      height: 45px;
    }
    @media ${device.desktop1280} {
      height: auto;
    }
    @media (max-width: 320px) {
      width: 50vw;
    }
  }
`;

export const InputWrapper = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.emptySelectHoverBackground};
  border: 1px solid ${({ theme }) => theme.grayText};
  border-radius: 20px;
  padding: 32px 20px;
  margin-bottom: 50px;
`;
