import styled from 'styled-components';
import device from '../../device';

export const PendingGrid = styled.div`
  z-index: 2;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 153px;
  padding: 40px 40px 0 40px;
  grid-template-columns: 1fr 1fr;
  background: #fff;
  box-shadow: 0px 10px 30px rgba(16, 58, 121, 0.15);
  border-radius: 12px;
  @media ${device.tablet} {
    padding: 25px 10px 0 10px;
  }
  @media ${device.mobileL} {
    margin-bottom: 10px;
  }
`;

export const PendingWrapper = styled.div`
  max-width: 926px;
  margin: 0 auto;
`;

export const InfoRequestWrapper = styled.div`
  padding: 0 0 45px 30px;
  @media ${device.tablet} {
    font-size: 24px;
    padding: 0 25px 30px 25px;
  }
  @media ${device.mobileL} {
    font-size: 24px;
    padding: 0 0 30px 10px;
  }
`;

export const DatesWrapper = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex-wrap: wrap;
  @media ${device.tablet} {
    font-size: 18px;
    line-height: 17px;
  }
  letter-spacing: 0;
`;

export const ActionButtonWrapper = styled.div`
  padding: 30px 90px 30px 90px;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @media ${device.tablet} {
    padding: 20px 10px;
    grid-template-columns: 1fr;
  }
`;

export const StyledPendingInstruction = styled.div`
  grid-column: 1 / -1;
  padding: 0 0 0 30px;
  @media ${device.tablet} {
    padding: 0 0 0 10px;
  }
`;

export const Instruction = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 18px;
  line-height: 22px;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  display: flex;
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Title = styled.div`
  line-height: 32px;
  padding: ${({ padding }) => padding || '30px 0 30px 0'};
  box-sizing: border-box;
  color: ${props => props.theme.blackText};
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const BlockTitle = styled.div`
  color: ${props => props.color || props.theme.blackText};
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  @media ${device.tablet} {
    font-size: 24px;
    line-height: 30px;
    padding: 30px 0 30px 10px;
  }
  padding: 30px 0 30px 0;
  margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
`;
